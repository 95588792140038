import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import RecherchePage from './sous page/RecherchePage';

const Recherche = () => {
    return (
        <div>
            <Navbar/>
            <RecherchePage/>
            <Footer/>
        </div>
    );
};

export default Recherche;