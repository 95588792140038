import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';

const GestionFournisseurs = () => {
    const [fournisseurs, setFournisseurs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const itemsPerPage = 8;

    useEffect(() => {
        // Récupération des fournisseurs avec pagination
        axios
            .get(`${ConfigHost.API_URL}/api/fournisseur/?limit=${itemsPerPage}&offset=${(currentPage - 1) * itemsPerPage}`)
            .then(response => {
                setFournisseurs(response.data.results);
                setTotalPages(Math.ceil(response.data.count / itemsPerPage));
            })
            .catch(error => {
                console.error('La récupération des fournisseurs a rencontré une erreur !', error);
            });
    }, [currentPage]);

    const handleDelete = (id) => {
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer ce fournisseur ?');
        if (confirmDelete) {
            axios
                .delete(`${ConfigHost.API_URL}/api/fournisseur/${id}/`)
                .then(() => {
                    setFournisseurs(fournisseurs.filter(fournisseur => fournisseur.id !== id));
                    alert('Fournisseur supprimé avec succès !');
                })
                .catch(error => {
                    console.error('Erreur lors de la suppression du fournisseur', error);
                });
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold text-green-600 mb-4">Gestion des Fournisseurs</h1>
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-green-500 text-white">
                        <th className="py-2 px-4 text-left">Nom d'utilisateur</th>
                        <th className="py-2 px-4 text-left">Nom</th>
                        <th className="py-2 px-4 text-left">Prénom</th>
                        <th className="py-2 px-4 text-left">Email</th>
                        <th className="py-2 px-4 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {fournisseurs.map(fournisseur => (
                        <tr key={fournisseur.id} className="border-t">
                            <td className="py-2 px-4">{fournisseur.username}</td>
                            <td className="py-2 px-4">{fournisseur.last_name}</td>
                            <td className="py-2 px-4">{fournisseur.first_name}</td>
                            <td className="py-2 px-4">{fournisseur.email}</td>
                            <td className="py-2 px-4 text-center">
                                <button
                                    onClick={() => handleDelete(fournisseur.id)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <i className="bi bi-trash-fill"></i>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            <div className="mt-4 flex justify-center">
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 rounded-lg ${currentPage === index + 1 ? 'bg-green-500 text-white' : 'bg-gray-200 hover:bg-green-300'}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default GestionFournisseurs;
