// src/pages/CartPage.js

import React, { useState } from 'react';
import { useCart } from '../../CartContext';
import OrangeMoney from '../../../components/OrangeMoney';
import CarteBancaire from '../../../components/CarteBancaire';

const countries = {
    'Bénin': ['Cotonou', 'Porto-Novo', 'Parakou'],
    'Burkina Faso': ['Ouagadougou', 'Bobo-Dioulasso', 'Koudougou'],
    'Cabo Verde': ['Praia', 'Mindelo', 'Santa Maria'],
    'Côte d\'Ivoire': ['Abidjan', 'Bouaké', 'Yamoussoukro'],
    'Gambie': ['Banjul', 'Serrekunda', 'Brikama'],
    'Ghana': ['Accra', 'Kumasi', 'Tamale'],
    'Guinée': ['Conakry', 'Nzérékoré', 'Kankan'],
    'Guinée-Bissau': ['Bissau', 'Bafatá', 'Gambie'],
    'Liberia': ['Monrovia', 'Gbarnga', 'Buchanan'],
    'Mali': ['Bamako', 'Ségou', 'Kayes'],
    'Niger': ['Niamey', 'Zinder', 'Maradi'],
    'Nigeria': ['Lagos', 'Abuja', 'Port Harcourt'],
    'Sénégal': ['Dakar', 'Saint-Louis', 'Thiès'],
    'Sierra Leone': ['Freetown', 'Bo', 'Kenema'],
    'Togo': ['Lomé', 'Sokodé', 'Kpalimé']
};

const CartPage = () => {
    const [shippingDetails, setShippingDetails] = useState({
        adresse: '',
        ville: '',
        pays: '',
        numeroTelephone: '',
    });
    const [selectedRadio, setSelectedRadio] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const { cart, removeFromCart } = useCart();

    const handleShippingChange = (e) => {
        const { name, value } = e.target;
        setShippingDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));

        // Si le champ modifié est "pays", réinitialiser la ville
        if (name === 'pays') {
            setShippingDetails(prevDetails => ({
                ...prevDetails,
                ville: ''
            }));
        }
    };

    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setIsVisible(true);


        setLoading(false);
    };

    const handleConfirmClick = () => {
        console.log('selectedRadio:', selectedRadio);
        setModalType(selectedRadio);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirm = () => {
    };

    const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);
    const subtotal = cart.reduce((acc, item) => acc + item.prix * item.quantity, 0);

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Passer votre commande</h1>
            <p className='my-4'>Veuillez renseigner les informations pour l'expedition de votre commande</p>
            <div className="flex flex-col md:flex-row md:gap-4">
                <form
                    onSubmit={handleSubmit}
                    className="w-full space-y-4 md:w-1/2 border border-gray-300 rounded-lg py-4 px-12 h-auto bg-white shadow-lg"
                >

                    {/* Pays */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Pays</label>
                        <select
                            name="pays"
                            value={shippingDetails.pays}
                            onChange={handleShippingChange}
                            className="mt-1 block w-full bg-gray-200 border border-gray-300 rounded-full shadow-sm p-2 text-gray-800 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500"
                            required
                        >
                            <option value="">Sélectionnez un pays</option>
                            {Object.keys(countries).map(country => (
                                <option key={country} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Ville */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Ville</label>
                        <select
                            name="ville"
                            value={shippingDetails.ville}
                            onChange={handleShippingChange}
                            className="mt-1 block w-full bg-gray-200 border border-gray-300 rounded-full shadow-sm p-2 text-gray-800 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500"
                            required
                            disabled={!shippingDetails.pays} // Désactiver si aucun pays n'est sélectionné
                        >
                            <option value="">Sélectionnez une ville</option>
                            {shippingDetails.pays && countries[shippingDetails.pays].map(city => (
                                <option key={city} value={city}>
                                    {city}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Adresse */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Adresse</label>
                        <input
                            type="text"
                            name="adresse"
                            value={shippingDetails.adresse}
                            onChange={handleShippingChange}
                            placeholder="Entrez votre adresse"
                            className="mt-1 block w-full bg-gray-200 border border-gray-300 rounded-full shadow-sm p-2 text-gray-800 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500"
                            required
                        />
                    </div>

                    {/* Numéro de téléphone */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Numéro de téléphone</label>
                        <input
                            type="number"
                            name="numeroTelephone"
                            value={shippingDetails.numeroTelephone}
                            onChange={handleShippingChange}
                            placeholder="Entrez votre numéro de téléphone"
                            className="mt-1 block w-full bg-gray-200 border border-gray-300 rounded-full shadow-sm p-2 text-gray-800 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <div className="w-full my-5">
                        <h3 className='my-4'>Choisissez votre moyen de paiement</h3>
                        <div className="flex justify-between py-2 px-2 border border-gray-200 rounded-lg">
                            <label className="label cursor-pointer">
                                <input
                                    type="radio"
                                    name="radio"
                                    value="Orange Money"
                                    checked={selectedRadio === 'Orange Money'}
                                    onChange={handleRadioChange}
                                    className="radio checked:bg-orange-500 mr-2"
                                />
                            <span className="label-text">Orange Money</span>
                            </label>
                            <label className="label cursor-pointer">
                                <input
                                    type="radio"
                                    name="radio"
                                    value="Carte Bancaire"
                                    checked={selectedRadio === 'Carte Bancaire'}
                                    onChange={handleRadioChange}
                                    className="radio checked:bg-blue-500 mr-2"
                                />
                            <span className="label-text">Carte Bancaire</span>
                            </label>
                        </div>
                    </div>

                    {/* Bouton */}
                    <button
                        type="submit"
                        className="w-full bg-green-500 text-white p-3 rounded-full shadow-md hover:bg-green-600 transition duration-300 ease-in-out"
                        disabled={loading}
                    >
                        {loading ? 'En cours...' : 'Continuer'}
                    </button>
                </form>


                <div className={`mt-8 md:w-1/2 border border-gray-400 rounded-lg p-3 ${isVisible ? 'block' : 'hidden'}`}>
                    <h2 className="text-xl font-bold mb-4 text-center">Résumé de la commande</h2>
                    <div className="bg-gray-100 p-4 rounded-md shadow-md">
                        <p className="text-xl">Total d'articles: {totalItems}</p>
                        <p className="text-xl">Sous-total: {subtotal.toFixed(2)} FCFA</p>
                        <ul className="mt-4">
                            {cart.map(item => (
                                <li key={item.id} className="flex justify-between py-2 border-b">
                                    <span>{item.titre}</span>
                                    <span>Prix: {item.prix} FCFA</span>
                                    <span>Quantité: {item.quantity}</span>
                                    <button
                                        onClick={() => removeFromCart(item.id)}
                                        className="text-red-500 hover:text-red-700 ml-2"
                                        aria-label="Supprimer">
                                        <i className="text-lg bi bi-trash"></i>
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {selectedRadio && (
                            <p className="mt-4 font-semibold">Moyen de Paiement : <span className='text-gray-500'>{selectedRadio}</span></p>
                        )}
                        <p className="mt-4 font-semibold">Adresse : <span className='text-gray-500'>{shippingDetails.adresse}</span></p>
                        <p className="font-bold">Ville : <span className='text-gray-500'>{shippingDetails.ville}</span></p>
                        <p className="font-bold">Pays : <span className='text-gray-500'>{shippingDetails.pays}</span></p>
                        <p className="font-bold">Numéro de téléphone : <span className='text-gray-500'>{shippingDetails.numeroTelephone}</span></p>
                        <button
                            type="button"
                            onClick={handleConfirmClick}
                            className="btn text-white bg-green-500 mt-2 mx-auto hover:bg-green-600"
                        >
                            Confirmer et payer
                        </button>
                    </div>
                </div>
            </div>

            {/* Modals */}
            {modalType === 'Orange Money' && (
                <OrangeMoney
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    totalAmount={subtotal}
                    cart={cart}
                />
            )}
            {modalType === 'Carte Bancaire' && (
                <CarteBancaire
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirm}
                    totalAmount={subtotal}
                    cart={cart}
                />
            )}
        </div>
    );
};

export default CartPage;
