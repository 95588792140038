import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link, useParams } from 'react-router-dom';
import { useCart } from '../../CartContext';
import ConfigHost from '../../../ConfigHost';
import { a } from 'react-router-dom';
import ChatBox from '../../ChatBox';
import Connexion from '../sous page/seConnecter/Connexion';
import Formulaire from './inscription/Formulaire';

const ProduitDetail = () => {
    const { id } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showChatBox, setShowChatBox] = useState(false);
    const [isShowPopup, setShowPopup] = useState(false);
    const [hasReviewed, setHasReviewed] = useState(false);
    const [hasRevieweds, setHasRevieweds] = useState(false);
    const [product, setProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [userId] = useState(localStorage.getItem('user_id'));
    const { addToCart } = useCart();
    const [produits, setProduits] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalComments, setTotalComments] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchProductData = async (page = 1) => {
            try {
                const productResponse = await axios.get(`${ConfigHost.API_URL}/api/produits/${id}/`);
                setProduct(productResponse.data);

                const produitsResponse = await axios.get(`${ConfigHost.API_URL}/api/produits/`);
                setProduits(produitsResponse.data);

                if (userId) {
                    const reviewResponse = await axios.get(`${ConfigHost.API_URL}/api/note/?client=${userId}&produit=${id}`);
                    if (reviewResponse.data.length > 0) {
                        setHasReviewed(true);
                    }

                    const reviewResponses = await axios.get(`${ConfigHost.API_URL}/api/four_note/?client=${userId}&fournisseur=${productResponse.data.fournisseur}`);
                    if (reviewResponses.data.length > 0) {
                        setHasRevieweds(true);
                    }
                }

                // Affichage par défaut des commentaires sur la page
                const limit = 9;
                const offset = (page - 1) * limit;
                const commentsResponse = await axios.get(`${ConfigHost.API_URL}/api/commentaires/?produit=${id}&limit=${limit}&offset=${offset}`);
                setComments(commentsResponse.data.results);
                setTotalComments(commentsResponse.data.count); // Total des commentaires disponibles
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        fetchProductData(currentPage);
    }, [id, userId, currentPage]);

    const handleLikeClick = async () => {
        if (userId && product) {
            try {
                await axios.post(`${ConfigHost.API_URL}/api/note/`, {
                    client: userId,
                    produit: product.id,
                    note_value: 5
                });
                setHasReviewed(true);
            } catch (error) {
                console.error('Erreur lors de l\'enregistrement de la note', error);
            }
        } else {
            openModal();
        }
    };

    const handleLikeClickFournisseur = async () => {
        if (userId && product.fournisseur && !hasRevieweds) {
            try {
                await axios.post(`${ConfigHost.API_URL}/api/four_note/`, {
                    client: userId,
                    fournisseur: product.fournisseur,
                    note_value: 5,
                });
                setHasRevieweds(true);
                console.log("Fournisseur aimé avec succès.");
            } catch (error) {
                console.error("Erreur lors de l'enregistrement de la note du fournisseur", error);
            }
        } else if (!userId) {
            openModal();
            console.log("Vous devez être connecté pour aimer un fournisseur.");
        }
    };

    const handleAddToCart = () => {
        if (!userId) {
            openModal();
            return;
        }

        if (product && quantity > 0) {
            addToCart(product, quantity);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleCommentSubmit = async () => {
        if (!userId) {
            openModal();
            return;
        }

        if (comment.trim() !== '') {
            try {
                await axios.post(`${ConfigHost.API_URL}/api/commentaires/`, {
                    client: userId,
                    produit: product.id,
                    texte_commentaire: comment
                });
                setComment('');
                // Recharger les commentaires après ajout
                const limit = 9;
                const offset = (currentPage - 1) * limit;
                const commentsResponse = await axios.get(`${ConfigHost.API_URL}/api/commentaires/?produit=${id}&limit=${limit}&offset=${offset}`);
                setComments(commentsResponse.data.results);
                setSuccessMessage('Votre commentaire a été ajouté avec succès !');
                console.log('Commentaire envoyé avec succès');

                // Cacher le message après 3 secondes
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            } catch (error) {
                console.error('Erreur lors de l\'enregistrement du commentaire', error);
            }
        } else {
            console.log('Le commentaire est vide');
        }
    };

    const toggleChatBox = () => {
        if (userId) {
            setShowChatBox(!showChatBox);
        } else {
            openModal();
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    //ferme le chat box si on clique à l'extérieur
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.chatbox-container') === null) {
                setShowChatBox(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    if (!product) return <div>Loading...</div>;

    const filteredProduits = produits.filter(produit => {
        return (!selectedCategory || produit.categorie === selectedCategory);
    });

    const FiveProduits = produits.slice(0, 5);


    return (
        <div className='py-8 px-4 w-full'>
            <p className='text-xl mb-7 mt-12 pl-4'>
                <Link to="/" className='mr-1 hover:text-green-500 hover:underline'>
                    Accueil
                </Link >
                / <span className='text-gray-500'>Produit de details</span></p>
            <div className="w-full bg-gray-50 md:flex">
                <div className="bg-gray-50 w-12/12 p-2 rounded-lg lg:w-3/5">
                    <div className="w-full">
                        <button
                            onClick={handleLikeClick}
                            className={`btn btn-a text-3xl rounded-full ${hasReviewed ? 'text-gray-200 cursor-not-allowed' : 'text-green-700 hover:text-gray-600'} relative bottom-0 left-3/4`}
                            aria-label="Like"
                            disabled={hasReviewed}
                        >
                            <i className="bi bi-heart"></i>
                        </button>
                    </div>
                    <img src={product.photo} alt={product.titre} className="md:w-5/6 object-cover rounded-xl" />
                </div>
                <div className="bg-gray-50 w-12/12 p-3 md:pl-20 rounded-lg mt-6 lg:w-3/5 flex flex-col justify-evenly">
                    <div className="w-full border-b border-gray-400">
                        <h1 className='text-2xl md:text-4xl text-gray-600 mb-3'>{product.titre}</h1>
                    </div>
                    <div className="border-b border-gray-400">
                        <h3 className='uppercase mb-2 mt-5 md:text-2xl'>Description</h3>
                        <p className='py-3 text-gray-600'>{product.description}</p>
                    </div>
                    <div>
                        <p className='md:text-2xl font-bold py-3 text-gray-700'>Prix : <span className='text-gray-500'>{product.prix} FCFA</span></p>
                    </div>
                    <div className='flex items-center mt-4'>
                        <input
                            type='number'
                            value={quantity}
                            onChange={e => setQuantity(Number(e.target.value))}
                            className='border border-gray-600 p-2 w-32 mr-4 rounded-full '
                            min='1'
                        />
                        <button
                            onClick={handleAddToCart}
                            className='bg-green-500 text-white md:text-xl p-2 rounded-full hover:bg-green-600'>
                            Commander
                        </button>
                    </div>
                </div>
            </div>
            <button
                onClick={toggleChatBox}
                className=" text-5xl fixed bottom-10 right-12 z-50 bg-green-500 text-white p-2 hover:text-green-100 rounded-full shadow-lg hover:bg-green-600"
            >
                <i className="bi bi-chat-fill"></i>
            </button>
            {showChatBox && (
                <div className="fixed bottom-4 right-4 z-40 bg-white p-4 rounded-lg shadow-lg chatbox-container">
                    <ChatBox />
                </div>
            )}
            <div className="w-10/12 mx-auto my-4 md:flex md:justify-between">
                <div className="md:w-1/2 items-center my-4 lg:gap-5 lg:w-2/4 p-4 border border-gray-300 rounded-lg">
                    <h2 className='md:text-2xl pb-4 border-b uppercase text-center'>informations sur le vendeur</h2>
                    <div className="w-full py-4 rounded-lg flex justify-between border-b">
                        <h2 className="md:text-xl text-gray-500">Vendu par :<span className='pl-2 underline'>{product.fournisseur_username}</span></h2>
                        <button
                            onClick={handleLikeClickFournisseur}
                            className={`btn btn-a text-3xl rounded-full ${hasRevieweds ? 'text-gray-200 cursor-not-allowed' : 'text-green-700 hover:text-gray-600'}`}
                            aria-label="Likes"
                            disabled={hasRevieweds} // Bouton désactivé si déjà aimé
                        >
                            <i className="bi bi-heart"></i>
                        </button>
                        <Connexion isOpen={isModalOpen} onClose={closeModal} />
                        <Formulaire isOpen={isShowPopup} onClose={closePopup} />
                    </div>
                    <div className="w-full">
                        <h4 className='capitalize md:text-2xl text-center'>performance du vendeur</h4>
                        <ul className="text-gray-500 p-2 md:text-xl">
                            <li><i className="mr-2 text-green-500 text-lg bi bi-patch-check-fill"></i>Expédition Rapide </li>
                            <li><i className="mr-2 text-green-500 text-lg bi bi-patch-check-fill"></i>Produit Authentique</li>
                            <li><i className="mr-2 text-green-500 text-lg bi bi-shield-fill-check"></i>Fournisseur de Quantité</li>
                        </ul>
                    </div>
                </div>
                <div className="md:w-1/3 border border-gray-200 mt-4 h-1/5 rounded-lg">
                <h2 className='text-2xl bg-gray-100 text-center mb-3 py-3 border-b'>
                    <i className="text-2xl text-green-600 bi bi-envelope-check-fill mr-4"></i>
                    Commentaire
                </h2>
                <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className='border border-gray-300 p-2 w-full rounded-lg'
                    rows='4'
                    placeholder='Écrivez votre commentaire ici ...'
                ></textarea>
                <button
                    onClick={handleCommentSubmit}
                    className='bg-green-500 text-white p-2 mt-2 rounded-lg hover:bg-green-600 mb-1'
                >
                    Ajouter un commentaire
                </button>
                {successMessage && (
                    <div className="mt-2 text-green-600 bg-green-100 p-2 rounded">
                        {successMessage}
                    </div>
                )}
            </div>
            </div>

            <div className="w-ful mt-14 p-2 bg-gray-50 rounded-lg">
                <h2 className="text-2xl md:text-4xl mb-4 mt-2 pl-3">Vous pourriez aussi aimer</h2>
                <div className="rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-3 py-8">
                    {FiveProduits.map(produit => (
                        <a key={produit.id} href={`/produit/${produit.id}`}>
                            <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                                <figure className='bg-gray-100 w-full h-3/5 rounded-xl'>
                                    <img src={produit.photo} alt={produit.titre} />
                                </figure>
                                <div className="px-2">
                                    <h2 className="text-md card-title my-2">
                                        {produit.titre.length > 23 ? `${produit.titre.slice(0, 23)}...` : produit.titre}
                                    </h2>
                                    <p className='text-sm text-gray-600 my-2'>{produit.description.length > 66 ? `${produit.description.slice(0, 66)}...` : produit.description}

                                    </p>
                                    <p className={`${produit.promo ? 'line-through text-gray-500' : ''}`}>
                                        {produit.prix} FCFA
                                    </p>
                                    {produit.promo && (
                                        <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                                            - {Math.round(100 - (100 * produit.promo / produit.prix))}%
                                        </p>
                                    )}

                                    {produit.promo && (
                                        <p className="text-red-500">Promo : {produit.promo} FCFA</p>
                                    )}
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>

            {/* Section pour afficher les commentaires récupérés */}
            <div className="w-full mt-2 p-2">
                <h2 className="text-2xl md:text-4xl mb-4">Les Commentaires sur le produit</h2>
                <div className="md:w-10/12 mx-auto my-6">
                    <div className='grid grid-cols-3 gap-4'>
                    {comments && comments.length > 0 ? (
                        comments.map(comment => (
                            <div key={comment.id} className="w-full flex flex-col gap-3 border border-green-200 rounded-xl p-4 bg-green-50/25">
                                <div className="w-full flex gap-3">
                                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                                        <div className="w-10 rounded-full">
                                            <img
                                                alt="User Avatar"
                                                src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
                                        </div>
                                    </div>
                                    <div>
                                        {comment.client_username ? (
                                            <h2 className='underline'>{comment.client_username}</h2>
                                        ) : (
                                            <p>Chargement...</p>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full">
                                    <p>{new Date(comment.date_avis).toLocaleDateString()}</p>
                                    <p>{comment.texte_commentaire}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center w-full text-gray-600">Aucun commentaire pour ce produit</p>
                    )}
                    </div>
                    {/* Pagination */}
                    <div className="mt-6 flex justify-between">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="bg-gray-400 text-white py-2 px-4 rounded"
                        >
                            Précédent
                        </button>

                        <span className="self-center text-lg text-gray-600">
                            Page {currentPage} sur {Math.ceil(totalComments / 9)}
                        </span>

                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === Math.ceil(totalComments / 9)}
                            className="bg-gray-400 text-white py-2 px-4 rounded"
                        >
                            Suivant
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProduitDetail;
