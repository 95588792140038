import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';
import AjoutProduit from '../../../pages/sous page/fournisseur/AjouProduit';
import ListeProduit from '../../../pages/sous page/fournisseur/ListeProduit';

const GestionProduits = () => {
    const [produits, setProduits] = useState([]);
    const [activeTab, setActiveTab] = useState('liste');
    const [userId] = useState(localStorage.getItem('user_id'));
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        titre: '',
        description: '',
        prix: '',
        categorie: '',
        promo: '',
        photo: null,
    });
    const [currentProduitId, setCurrentProduitId] = useState(null);

    useEffect(() => {
        fetchProduits();
    }, [userId]);

    const fetchProduits = async () => {
        try {
            const response = await axios.get(`${ConfigHost.API_URL}/api/produits/?fournisseur=${userId}`);
            setProduits(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des produits:', error);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        if (tab === 'ajout') {
            resetForm();
            setEditMode(false);
        }
    };

    const resetForm = () => {
        setFormData({
            titre: '',
            description: '',
            prix: '',
            categorie: '',
            promo: '',
            photo: null,
        });
        setCurrentProduitId(null);
    };

    const handleEdit = (id) => {
        const produit = produits.find(prod => prod.id === id);
        setFormData({
            titre: produit.titre,
            description: produit.description,
            prix: produit.prix,
            categorie: produit.categorie,
            promo: produit.promo ? produit.promo : '',
            photo: null,
        });
        setCurrentProduitId(id);
        setEditMode(true);
        setActiveTab('ajout');
    };        

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${ConfigHost.API_URL}/api/produits/${id}/`);
            fetchProduits();
        } catch (error) {
            console.error('Erreur lors de la suppression du produit:', error);
        }
    };

    return (
        <div className="container mx-auto p-4 mb-20">
            <h2 className='text-xl mb-7'>Accueil / <span className='text-gray-500'>Gestion de Produits</span></h2>
            <div className="flex mb-6">
                <button
                    onClick={() => handleTabChange('liste')}
                    className={`mr-4 px-4 py-2 ${activeTab === 'liste' ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-lg`}
                >
                    Liste des produits
                </button>
                <button
                    onClick={() => handleTabChange('ajout')}
                    className={`px-4 py-2 ${activeTab === 'ajout' ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-lg`}
                >
                    Ajouter un produit
                </button>
            </div>
            <div className="">
                {activeTab === 'liste' && (
                    <ListeProduit produits={produits} handleEdit={handleEdit} handleDelete={handleDelete} />
                )}
                {activeTab === 'ajout' && (
                    <AjoutProduit 
                        formData={formData} 
                        setFormData={setFormData} 
                        fetchProduits={fetchProduits} 
                        editMode={editMode} 
                        produitId={currentProduitId} 
                        resetForm={resetForm} 
                    />
                )}
            </div>
        </div>
    );
};

export default GestionProduits;
