import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConfigHost from '../../../ConfigHost';
import { a, useNavigate } from 'react-router-dom';

const FavorisPage = () => {
  const [produits, setProduits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchProduits = async () => {
      try {
        const produitResponse = await axios.get(`${ConfigHost.API_URL}/api/commandes/?client=${userId}`);

        // Récupérer les IDs des produits à partir de la réponse
        const productIds = produitResponse.data.map(command => command.produit); // Assurez-vous que 'produit' est le champ qui contient l'ID

        // Effectuer des requêtes pour récupérer les détails de chaque produit
        const produitsDetails = await Promise.all(
          productIds.map(id => axios.get(`${ConfigHost.API_URL}/api/produits/${id}/`))
        );

        // Extraire les données des produits
        const produitsData = produitsDetails.map(response => response.data);
        setProduits(produitsData); // Mettre à jour l'état avec les détails des produits
      } catch (err) {
        setError('Erreur lors de la récupération des produits.');
      } finally {
        setLoading(false);
      }
    };

    if (userId) { // Vérifier si userId existe avant de faire la requête
      fetchProduits();
    } else {
      setError('Utilisateur non connecté.');
      setLoading(false);
    }
  }, [userId]);

  if (loading) {
    return <div className="text-center text-lg">Chargement...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="max-w-full p-6 bg-white rounded-lg shadow-md mt-24">
      <h1 className="text-3xl font-bold mb-6 text-center">Mes Produits Favoris sur <span className='text-red-500'>Consom<span className='text-green-500'>Local</span ></span></h1>
      {produits.length > 0 ? (
        <div className="w-full rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 mt-1 p-8">
          {produits.map((produit) => (
            <a key={produit.id} href={`/produit/${produit.id}`}>
              <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                <figure className='bg-gray-100 w-full h-2/5 rounded-xl'>
                  <img src={produit.photo} alt={produit.titre} />
                </figure>
                <div className="px-2">
                  <h2 className="text-md card-title my-2">
                    {produit.titre.length > 23 ? `${produit.titre.slice(0, 23)}...` : produit.titre}
                  </h2>
                  <p className={`${produit.promo ? 'line-through text-gray-500' : ''}`}>
                    {produit.prix} FCFA
                  </p>
                  {produit.promo && (
                    <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                      - {Math.round(100 - (100 * produit.promo / produit.prix))}%
                    </p>
                  )}

                  {produit.promo && (
                    <p className="text-red-500">Promo : {produit.promo} FCFA</p>
                  )}
                </div>
              </div>
            </a>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">Aucun produit favori trouvé.</p>
      )}
    </div>
  );
};

export default FavorisPage;
