import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfigHost from '../../../../ConfigHost';
import Client from '../../../../assets/compte.jpg';
import Fournisseur from '../../../../assets/fournisseur.png';
import Connexion from '../seConnecter/Connexion';

const Formulaire = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        userType: 'client',
        description: '',
        photo: null
    });

    const [step, setStep] = useState(1); // Étape du formulaire (1 ou 2)

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Réinitialiser le formulaire lorsque le popup est ouvert
    useEffect(() => {
        if (isOpen) {
            setStep(1);
            setFormData({
                username: '',
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                userType: 'client',
                description: '',
                photo: null
            });
        }
    }, [isOpen]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const endpoint = formData.userType === 'client'
            ? `${ConfigHost.API_URL}/api/client/`
            : `${ConfigHost.API_URL}/api/fournisseur/`;

        const data = new FormData(); // Utilisation de FormData pour les fichiers
        data.append('username', formData.username);
        data.append('first_name', formData.first_name);
        data.append('last_name', formData.last_name);
        data.append('email', formData.email);
        data.append('password', formData.password);
        data.append('userType', formData.userType);
        if (formData.userType === 'fournisseur') {
            data.append('description', formData.description);
            data.append('photo', formData.photo);
        }

        axios.post(endpoint, data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                onClose(); // Ferme le popup après succès
                navigate('/');
                console.log('Inscription réussie', response.data);
            })
            .catch(error => {
                console.error('Erreur lors de l\'inscription', error);
            });
    };

    const handleNextStep = () => {
        setStep(2); // Passer à l'étape suivante du formulaire
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
            <div className="w-11/12 md:w-3/6 md:h-4/5 p-1 bg-white rounded-lg shadow-lg relative flex justify-between">
                <div className='absolute top-0 right-0'>
                    <button onClick={onClose} className="text-red-500 mt-4 hover:underline mr-1">
                        Fermer
                    </button>
                </div>
                <div className="hidden md:block w-2/6 border border-gray-300 bg-green-100 rounded-br-full relative">
                    <div className="absolute top-1/2 right-0 m-4">
                        <i className="text-8xl opacity-10 bi bi-cart2"></i>
                    </div>
                    <div className="absolute top-0 left-1/2 m-4 transform rotate-45">
                        <i className="text-8xl opacity-10 bi-gift-fill"></i>
                    </div>
                    <div className="absolute bottom-1/2 left-0 m-4 transform rotate-45">
                        <i className="text-8xl opacity-10 bi-qr-code"></i>
                    </div>
                    <div className="absolute bottom-1/4 right-1/3 m-4">
                        <i className="text-8xl opacity-10 bi bi-patch-check"></i>
                    </div>
                </div>
                <div className="mx-auto w-11/12 md:w-4/6 flex flex-col justify-between items-center pt-4">
                    <div className="w-10/12">
                        <h2 className="text-2xl font-bold mb-5 text-center">
                            {step === 1 ? 'Création de compte' : 'Choisir l\'utilisateur'}
                        </h2>
                    </div>

                    <div className="w-full md:w-10/12">
                        {step === 1 ? (
                            <form onSubmit={handleNextStep}>
                            <div className="">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                    Nom d'utilisateur
                                </label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                                    placeholder="Entrez votre Nom utilisateur"
                                />
                            </div>
                            <div className="">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">
                                    Prénom
                                </label>
                                <input
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                                    placeholder="Entrez votre Prénom"
                                />
                            </div>
                            <div className="">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="last_name">
                                    Nom
                                </label>
                                <input
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                                    placeholder="Entrez votre Nom"
                                />
                            </div>
                            <div className="">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                                    placeholder="Entrez votre Adresse email"
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                    Mot de passe
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                                    placeholder="Au moins 6 caractères"
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                                    Confirmez le mot de passe
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                                    placeholder="Confirmez votre mot de passe"
                                />
                            </div>
                            {formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword && (
                                <p className="text-red-500 text-sm">Les mots de passe ne correspondent pas.</p>
                            )}
                            <div className="">
                                <button
                                    type="button"
                                    onClick={handleNextStep}
                                    disabled={formData.password !== formData.confirmPassword}
                                    className={`w-full px-4 py-2 rounded-lg ${
                                        formData.password === formData.confirmPassword
                                            ? 'bg-green-500 hover:bg-green-700 text-white'
                                            : 'bg-gray-400 cursor-not-allowed text-white'
                                    }`}
                                >
                                    Continuer
                                </button>
                            </div>
                        </form>                        
                        ) : (
                            <form onSubmit={handleSubmit} className='content-scrollable h-[calc(100vh-8rem)] overflow-y-auto'>
                                <div className="mb-4">
                                    <label className="block text-gray-500 text-3xl font-bold mb-1">
                                        Type d'utilisateur
                                    </label>
                                    <div className="w-full flex gap-2">
                                        <div
                                            className="w-1/2 flex flex-col items-center rounded-2xl border-2 border-gray-300 p-4 gap-3 hover:border-green-300"
                                            onClick={() => setFormData({ ...formData, userType: 'client' })}
                                        >
                                            <div className="w-full flex justify-around">
                                                <input
                                                    type="radio"
                                                    id="client"
                                                    name="userType"
                                                    value="client"
                                                    checked={formData.userType === 'client'}
                                                    onChange={handleChange}
                                                    className="radio checked:bg-green-400"
                                                />
                                                <label htmlFor="client" className="text-gray-700 text-2xl">
                                                    Client
                                                </label>
                                            </div>
                                            <img src={Client} alt="Client" className="w-full object-cover" />
                                        </div>
                                        <div
                                            className="w-1/2 flex flex-col items-center rounded-2xl border-2 border-gray-300 p-4 gap-3 hover:border-green-300"
                                            onClick={() => setFormData({ ...formData, userType: 'fournisseur' })}
                                        >
                                            <div className="w-full flex justify-around">
                                                <input
                                                    type="radio"
                                                    id="fournisseur"
                                                    name="userType"
                                                    value="fournisseur"
                                                    checked={formData.userType === 'fournisseur'}
                                                    onChange={handleChange}
                                                    className="radio checked:bg-green-400"
                                                />
                                                <label htmlFor="fournisseur" className="text-gray-700 text-2xl">
                                                    Fournisseur
                                                </label>
                                            </div>
                                            <img src={Fournisseur} alt="Fournisseur" className="w-full object-cover" />
                                        </div>
                                    </div>
                                </div>
                                {formData.userType === 'fournisseur' && (
                                    <>
                                        <div className="">
                                            <label className="block text-gray-700 text-sm font-bold" htmlFor="description">
                                                Votre domaine d'activite
                                            </label>
                                            <input
                                                type="text"
                                                id="description"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                required
                                                className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                                                placeholder='Description de votre activite'
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="block text-gray-700 text-sm font-bold" htmlFor="photo">
                                                Photo de CNIB
                                            </label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="photo"
                                                onChange={handleChange}
                                                required
                                                className="w-full px-3 py-2 border rounded-lg bg-gray-100"
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="">
                                    <button
                                        type="submit"
                                        className="w-full bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700"
                                    >
                                        S'inscrire
                                    </button>
                                </div>
                                <Connexion isOpen={isModalOpen} onClose={closeModal} />
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Formulaire;
