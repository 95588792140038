import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';
import AjoutCommandePhysique from '../../../pages/sous page/admin/AjoutCommandePhysique';

const ListeCommandeManuelle = () => {
    const [userId] = useState(localStorage.getItem('user_id'));
    const [commandes, setCommandes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    // États pour le popup de filtrage
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Formulaire de modification
    const [isEditMode, setIsEditMode] = useState(false);
    const [commandeToEdit, setCommandeToEdit] = useState(null);

    useEffect(() => {
        fetchCommandes();
    }, [userId]);

    const fetchCommandes = async (startDate = null, endDate = null) => {
        setLoading(true);
        setError(null);

        try {
            const params = {
                fournisseur: userId,
                ...(startDate && { date_interval_after: startDate }),
                ...(endDate && { date_interval_before: endDate }),
            };

            const response = await axios.get(`${ConfigHost.API_URL}/api/commandes_physique/`, { params });
            setCommandes(response.data);
        } catch (error) {
            setError('Erreur lors de la récupération des commandes');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (commandeId) => {
        try {
            await axios.delete(`${ConfigHost.API_URL}/api/commandes_physique/${commandeId}/`);
            setCommandes(commandes.filter(commande => commande.id !== commandeId));
        } catch (error) {
            setError('Erreur lors de la suppression de la commande');
            console.error(error);
        }
    };

    const handleEdit = (commande) => {
        setCommandeToEdit(commande);
        setIsEditMode(true);
    };

    const handleCancelEdit = () => {
        setCommandeToEdit(null);
        setIsEditMode(false);
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCommandes = commandes.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(commandes.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleFilterSubmit = () => {
        fetchCommandes(startDate, endDate);
        setIsFilterPopupOpen(false);
    };

    return (
        <div className="py-6">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Liste des commandes physiques</h3>

            <div className="mb-4">
                <button
                    className="bg-green-600 text-white px-4 py-2 rounded shadow hover:bg-green-700"
                    onClick={() => setIsFilterPopupOpen(true)}
                >
                    Filtrer
                </button>
            </div>

            {!isEditMode ? (
                <>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white divide-y divide-gray-200 shadow-md rounded-lg">
                            <thead className="bg-green-600 text-white">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase">Client</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase">Produit</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase">Quantité</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase">Prix Unitaire</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase">Total</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase">Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {currentCommandes.map((commande) => (
                                    <tr key={commande.id} className="hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{commande.last_name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.produit_titre}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.quantite}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.prix} FCFA</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.total} FCFA</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(commande.created_at).toLocaleDateString()}</td>
                                        <td className="flex justify-center py-4 whitespace-nowrap text-sm text-gray-500 gap-3">
                                            <button
                                                onClick={() => handleEdit(commande)}
                                                className="text-lg text-green-600 hover:text-green-900"
                                            >
                                                <i className="bi bi-pencil"></i>
                                            </button>
                                            <button
                                                onClick={() => handleDelete(commande.id)}
                                                className="text-lg text-red-600 hover:text-red-900"
                                            >
                                                <i className="bi bi-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-between mt-4">
                        <button
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                            className={`px-4 py-2 border rounded ${currentPage === 1 ? 'bg-gray-200 text-gray-500' : 'bg-white text-gray-800 hover:bg-green-200'}`}
                        >
                            Précédent
                        </button>
                        <span className="px-4 py-2">
                            Page {currentPage} sur {totalPages}
                        </span>
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            className={`px-4 py-2 border rounded ${currentPage === totalPages ? 'bg-gray-200 text-gray-500' : 'bg-white text-gray-800 hover:bg-green-200'}`}
                        >
                            Suivant
                        </button>
                    </div>
                </>
            ) : (
                <AjoutCommandePhysique
                    initialData={commandeToEdit}
                    onCancel={handleCancelEdit}
                    onUpdate={fetchCommandes}
                />
            )}
        </div>
    );
};

export default ListeCommandeManuelle;
