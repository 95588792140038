import React from 'react';

const EspaceCommentaire = ({ commentaires, setCurrentPage }) => {
    return (
        <div>
            <div className="w-full mt-14 p-2">
                <h2 className="text-4xl mb-4">Les Commentaires</h2>
                <div className="w-11/12 mx-auto grid grid-cols-3 gap-4 my-6">
                    {commentaires && commentaires.length > 0 ? (
                        commentaires.map((commentaire) => (
                            <div
                                key={commentaire.id}
                                className="flex flex-col gap-3 border border-green-200 rounded-xl p-4 bg-green-50/25"
                            >
                                <div className="w-full flex gap-3">
                                    <div
                                        tabIndex={0}
                                        role="button"
                                        className="btn btn-ghost btn-circle avatar"
                                    >
                                        <div className="w-10 rounded-full">
                                            <img
                                                alt="User Avatar"
                                                src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {commentaire.client_username ? (
                                            <>
                                                <h2 className="underline">
                                                    {commentaire.client_username}
                                                </h2>
                                                <p>Produit: {commentaire.produit_titre}</p>
                                            </>
                                        ) : (
                                            <p>Chargement...</p>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full">
                                    <p>
                                        {new Date(commentaire.date_avis).toLocaleDateString()}
                                    </p>
                                    <p>{commentaire.texte_commentaire}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center w-full text-gray-600">
                            Aucun commentaire pour ce produit
                        </p>
                    )}
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        className="px-4 py-2 bg-gray-300 rounded"
                    >
                        Précédent
                    </button>
                    <button
                        onClick={() => setCurrentPage((prev) => prev + 1)}
                        className="px-4 py-2 bg-gray-300 rounded"
                    >
                        Suivant
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EspaceCommentaire;
