import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';

const TableauDeBordAdmin = () => {
    const [stats, setStats] = useState({
        total_clients: 0,
        total_fournisseurs: 0,
        total_products: 0,
    });

    const [clients, setClients] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [currentPageClient, setCurrentPageClient] = useState(1);
    const [currentPageFournisseur, setCurrentPageFournisseur] = useState(1);
    const [totalClients, setTotalClients] = useState(0);
    const [totalFournisseurs, setTotalFournisseurs] = useState(0);
    const [meilleurProduit, setMeilleurProduit] = useState(null);
    const [meilleurProduitVentes, setMeilleurProduitVentes] = useState(0);

    const clientsPerPage = 5; // Nombre de clients par page
    const fournisseursPerPage = 5; // Nombre de fournisseurs par page

    // Fonction pour récupérer les clients inscrits aujourd'hui
    const fetchClients = async (page) => {
        try {
            const today = new Date().toISOString().split('T')[0];
            const response = await axios.get(`${ConfigHost.API_URL}/api/client/?date_du_jour=${today}`, {
            });
            setClients(response.data);
            setTotalClients(response.data.count);
        } catch (error) {
            console.error('Erreur lors de la récupération des clients:', error);
        }
    };

    // Fonction pour récupérer les fournisseurs inscrits aujourd'hui
    const fetchFournisseurs = async (page) => {
        try {
            const today = new Date().toISOString().split('T')[0];
            const response = await axios.get(`${ConfigHost.API_URL}/api/fournisseur/?date_du_jour=${today}`, {
            });
            setFournisseurs(response.data);
            setTotalFournisseurs(response.data.count);
        } catch (error) {
            console.error('Erreur lors de la récupération des fournisseurs:', error);
        }
    };

    useEffect(() => {

        const fetchMeilleurProduit = async () => {
            try {
                const response = await axios.get(`${ConfigHost.API_URL}/api/commandes/`);
                const commandes = response.data;

                if (commandes.length > 0) {
                    // Compter le nombre de commandes par produit
                    const produitCount = commandes.reduce((acc, commande) => {
                        const produitId = commande.produit;
                        acc[produitId] = (acc[produitId] || 0) + 1;
                        return acc;
                    }, {});

                    // Identifier le meilleur produit
                    const meilleurProduitId = Object.keys(produitCount).reduce((a, b) => produitCount[a] > produitCount[b] ? a : b);
                    setMeilleurProduitVentes(produitCount[meilleurProduitId]);

                    const produitResponse = await axios.get(`${ConfigHost.API_URL}/api/produits/${meilleurProduitId}/`);
                    setMeilleurProduit(produitResponse.data);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du meilleur produit :", error);
            }
        };


        fetchMeilleurProduit();
        fetchClients(currentPageClient);
        fetchFournisseurs(currentPageFournisseur);
    }, [currentPageClient, currentPageFournisseur]);

    // Pagination
    const handlePageChangeClient = (page) => {
        setCurrentPageClient(page);
    };

    const handlePageChangeFournisseur = (page) => {
        setCurrentPageFournisseur(page);
    };

    useEffect(() => {
        fetch('http://localhost:8000/api/dashboard-stats/') // Remplacez par l'URL de votre backend
            .then((response) => response.json())
            .then((data) => {
                setStats(data);
            })
            .catch((error) => console.error('Erreur lors de la récupération des statistiques:', error));
    }, []);

    return (
        <div className="w-full">
            <div className="w-11/12 mx-auto grid gap-3 lg:flex lg:gap-14">
                <div className="stat border border-green-300 rounded-lg shadow-lg">
                    <h3 className="text-gray-400 text-lg lg:text-xl">Total Client</h3>
                    <div className="lg:mt-2 flex justify-between">
                        <p className="text-xl lg:text-3xl mt-4">{stats.total_clients}</p>
                        <i className="bg-green-500 rounded-xl lg:py-2 lg:px-3 px-2 text-white text-lg lg:text-2xl mt-4 bi bi-bag-check-fill"></i>
                    </div>
                </div>
                <div className="stat border border-green-300 rounded-lg shadow-lg">
                    <h3 className="text-gray-400 text-lg lg:text-xl">Total Fournisseur</h3>
                    <div className="lg:mt-2 flex justify-between">
                        <p className="text-xl lg:text-3xl mt-4">{stats.total_fournisseurs}</p>
                        <i className="bg-green-500 rounded-xl lg:py-2 lg:px-3 px-2 text-white text-lg lg:text-2xl mt-4 bi bi-chat-fill"></i>
                    </div>
                </div>
                <div className="stat border border-green-300 rounded-lg shadow-lg">
                    <h3 className="text-gray-400 text-lg lg:text-xl">Nombre de produit</h3>
                    <div className="lg:mt-2 flex justify-between">
                        <p className="text-xl lg:text-3xl mt-4">{stats.total_products}</p>
                        <i className="bg-green-500 rounded-xl lg:py-2 lg:px-3 px-2 text-white text-lg lg:text-2xl mt-4 bi bi-card-checklist"></i>
                    </div>
                </div>
            </div>
            <div className="w-12/12 mx-auto grid px-6 lg:flex lg:gap-14 mt-8">
                {/* Tableau des Clients */}
                <div className="stat border border-gray-300 rounded-lg shadow-lg w-1/2">
                    <h3 className="text-gray-600 text-lg lg:text-xl">Clients Inscrits Aujourd'hui</h3>
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead>
                                <tr>
                                    <th className="border px-4 py-2">Username</th>
                                    <th className="border px-4 py-2">Nom</th>
                                    <th className="border px-4 py-2">Prenom</th>
                                    <th className="border px-4 py-2">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clients.map((client) => (
                                    <tr key={client.id}>
                                        <td className="border px-4 py-2">{client.username}</td>
                                        <td className="border px-4 py-2">{client.last_name}</td>
                                        <td className="border px-4 py-2">{client.first_name}</td>
                                        <td className="border px-4 py-2">{client.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination flex justify-center mt-4">
                        <button
                            onClick={() => handlePageChangeClient(currentPageClient - 1)}
                            disabled={currentPageClient === 1}
                            className="px-4 py-2 bg-green-500 text-white rounded-l"
                        >
                            Précédent
                        </button>
                        <span className="px-4 py-2">{currentPageClient}</span>
                        <button
                            onClick={() => handlePageChangeClient(currentPageClient + 1)}
                            disabled={currentPageClient * clientsPerPage >= totalClients}
                            className="px-4 py-2 bg-green-500 text-white rounded-r"
                        >
                            Suivant
                        </button>
                    </div>
                </div>

                {/* Tableau des Fournisseurs */}
                <div className="stat border border-gray-300 rounded-lg shadow-lg w-1/2">
                    <h3 className="text-gray-600 text-lg lg:text-xl">Fournisseurs Inscrits Aujourd'hui</h3>
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead>
                                <tr>
                                    <th className="border px-4 py-2">Username</th>
                                    <th className="border px-4 py-2">Nom</th>
                                    <th className="border px-4 py-2">Prenom</th>
                                    <th className="border px-4 py-2">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fournisseurs.map((fournisseur) => (
                                    <tr key={fournisseur.id}>
                                        <td className="border px-4 py-2">{fournisseur.username}</td>
                                        <td className="border px-4 py-2">{fournisseur.last_name}</td>
                                        <td className="border px-4 py-2">{fournisseur.first_name}</td>
                                        <td className="border px-4 py-2">{fournisseur.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination flex justify-center mt-4">
                        <button
                            onClick={() => handlePageChangeFournisseur(currentPageFournisseur - 1)}
                            disabled={currentPageFournisseur === 1}
                            className="px-4 py-2 bg-green-500 text-white rounded-l"
                        >
                            Précédent
                        </button>
                        <span className="px-4 py-2">{currentPageFournisseur}</span>
                        <button
                            onClick={() => handlePageChangeFournisseur(currentPageFournisseur + 1)}
                            disabled={currentPageFournisseur * fournisseursPerPage >= totalFournisseurs}
                            className="px-4 py-2 bg-green-500 text-white rounded-r"
                        >
                            Suivant
                        </button>
                    </div>
                </div>
            </div>
            <div className="ml-8 mt-8 lg:w-1/2 border border-gray-300 rounded-lg">
                <h3 className='py-4 px-5 border-b'>Meilleur Produit</h3>
                <div className="w-full p-3 flex justify-around ">
                    {meilleurProduit ? (
                        <>
                            <img src={meilleurProduit.photo} alt={meilleurProduit.titre} className="w-1/6 h-16 rounded-lg" />
                            <div className="flex flex-col w-10/12 p-3">
                                <div className="flex justify-between">
                                    <h3>{meilleurProduit.titre}</h3>
                                    <p className="mt-2">{meilleurProduitVentes} Ventes</p> {/* Affichage du nombre de ventes */}
                                </div>
                                <p className="text-gray-500 mt-2 truncate-description">
                                    {meilleurProduit.description}
                                </p>
                            </div>


                        </>
                    ) : (
                        <p>Aucun produit trouvé.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TableauDeBordAdmin;
