import React, { useState } from 'react';
import axios from 'axios';
import ConfigHost from '../../../ConfigHost';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Envoi des données au backend via axios
      const response = await axios.post(`${ConfigHost.API_URL}/api/send-email/`, {
        name,
        prenom,
        email,
        telephone,
        message,
      });

      // Vérifier si l'e-mail a été envoyé avec succès
      if (response.status === 200) {
        setName('');
        setPrenom('');
        setEmail('');
        setTelephone('');
        setMessage('');
        setSuccess(true);
      } else {
        console.error('Erreur lors de l\'envoi de l\'e-mail.');
      }
    } catch (error) {
      console.error('Erreur réseau:', error);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 my-8 bg-white rounded-lg shadow-md border border-green-500">
      <h2 className="text-2xl font-bold mb-4 text-center text-green-600">Contactez-nous</h2>
      {success && <p className="mb-4 text-green-600 text-center">Votre message a été envoyé avec succès !</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-green-700">Nom:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="mt-1 block w-full border border-green-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="prenom" className="block text-sm font-medium text-green-700">Prénom:</label>
          <input
            type="text"
            id="prenom"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
            required
            className="mt-1 block w-full border border-green-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-green-700">E-mail:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="mt-1 block w-full border border-green-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="telephone" className="block text-sm font-medium text-green-700">Téléphone (WhatsApp):</label>
          <input
            type="tel"
            id="telephone"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            required
            className="mt-1 block w-full border border-green-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-sm font-medium text-green-700">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="mt-1 block w-full border border-green-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-green-300"
            rows="4"
          />
        </div>
        <button type="submit" className="w-full bg-green-600 text-white font-semibold py-2 rounded-md hover:bg-green-700 transition duration-200">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
