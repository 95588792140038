import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Connexion from '../../src/components/pages/sous page/seConnecter/Connexion';
import { useCart } from '../../src/components/CartContext';

const ProtectedRoute = ({ element, allowedFor }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userId = localStorage.getItem('user_id');
    const userType = localStorage.getItem('user_type'); // Récupérer le type d'utilisateur
    const { cart } = useCart(); // Utilisation du hook pour accéder au contenu du panier
    const navigate = useNavigate();

    useEffect(() => {
        // Vérification de la connexion de l'utilisateur
        if (!userId) {
            setIsModalOpen(true);
        }

        // Redirection si le panier est vide et qu'on accède à l'URL /commande
        if (window.location.pathname === '/commande' && cart.length === 0) {
            alert('Votre panier est vide. Veuillez ajouter des articles avant de continuer.');
            navigate('/');
        }

        // Vérification du type d'utilisateur pour les pages autorisées
        if (allowedFor && userType !== allowedFor) {
            alert('Vous n’êtes pas autorisé à accéder à cette page.');
            navigate('/');
        }
    }, [userId, userType, cart, allowedFor, navigate]);

    const closeModal = () => {
        setIsModalOpen(false);
        navigate('/'); // Redirection vers la page d'accueil après la fermeture de la modale
    };

    // Si l'utilisateur est connecté et autorisé, afficher la page
    if (userId && (!allowedFor || userType === allowedFor)) {
        return element;
    }

    // Si l'utilisateur n'est pas connecté, afficher la modale
    return (
        <>
            {isModalOpen && <Connexion isOpen={isModalOpen} onClose={closeModal} />}
        </>
    );
};

export default ProtectedRoute;
