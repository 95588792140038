import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';
import EspaceCommentaire from '../../../pages/sous page/fournisseur/EspaceCommentaire';
import EspaceNote from '../../../pages/sous page/fournisseur/EspaceNote';

const Evaluation = () => {
    const [activeTab, setActiveTab] = useState('commentaire');
    const [commentaires, setCommentaires] = useState([]);
    const [notes, setNotes] = useState([]);
    const userId = localStorage.getItem('user_id'); // Vérifiez si userId est bien récupéré
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null);

    const fetchCommentaires = async (page = 1) => {
        try {
            const limit = 9;
            const offset = (page - 1) * limit;
            const response = await axios.get(`${ConfigHost.API_URL}/api/commentaires/?fournisseur_id=${userId}&limit=${limit}&offset=${offset}`);
            setCommentaires(response.data.results); // Utilisation correcte des données paginées
        } catch (error) {
            setError('Erreur lors de la récupération des commentaires');
            console.error('Erreur:', error);
        }
    };

    const fetchNotes = async () => {
        try {
            const response = await axios.get(`${ConfigHost.API_URL}/api/note/?fournisseur_id=${userId}`);
            setNotes(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des notes:', error);
        }
    };

    useEffect(() => {
        if (activeTab === 'commentaire') {
            fetchCommentaires(currentPage);
        } else if (activeTab === 'note') {
            fetchNotes();
        }
    }, [activeTab, currentPage]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1); // Réinitialise la pagination lors du changement d'onglet
    };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-xl mb-7">
                Accueil / <span className="text-gray-500">Consulter les évaluations</span>
            </h2>
            <div className="flex mb-6">
                <button
                    onClick={() => handleTabChange('commentaire')}
                    className={`mr-4 px-4 py-2 ${
                        activeTab === 'commentaire' ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-700'
                    } rounded-lg`}
                >
                    Les commentaires sur vos produits
                </button>
                <button
                    onClick={() => handleTabChange('note')}
                    className={`px-4 py-2 ${
                        activeTab === 'note' ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-700'
                    } rounded-lg`}
                >
                    Voir les notes
                </button>
            </div>
            <div className="content-scrollable h-[calc(100vh-8rem)] overflow-y-auto">
                {activeTab === 'commentaire' && (
                    <EspaceCommentaire commentaires={commentaires} setCurrentPage={setCurrentPage} />
                )}
                {activeTab === 'note' && <EspaceNote notes={notes} />}
            </div>
        </div>
    );
};

export default Evaluation;
