import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Routes, Route, Link, useLocation } from 'react-router-dom';
import TableauDeBord from './fournisseur/TableauDeBord';
import GestionProduits from './fournisseur/GestionProduits';
import Solde from './fournisseur/Solde';
import Evaluation from './fournisseur/Evaluation';
import Message from './fournisseur/Message';
import GestionCommandes from './admin/GestionCommandes';
import Logo from '../../../assets/logo.png';

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [menuOpen, setMenuOpen] = useState(false); // État pour le menu hamburger
    const sidebarRef = useRef(null); // Référence pour le menu
    const [message, setMessage] = useState("");
    const username = localStorage.getItem('username');
    const totalItems = 5;

    const handleLogout = () => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir vous deconnecter ?");
        if (!confirmation) return;

        try {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('username');
            localStorage.removeItem('user_type');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_email');
            navigate('/');
        } catch (error) {
            setMessage("Erreur lors de la deconnection.");
        }
        
    };

    const handleLinkClick = (path) => {
        setActiveLink(path);
        setMenuOpen(false); // Fermer le menu hamburger automatiquement
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    return (
        <div className="flex flex-col h-screen overflow-hidden">
            {/* Header */}
            <header className="h-16 bg-gray-100 text-white flex items-center justify-between lg:flex lg:items-center lg:justify-between p-4 ">

                <div className=''>
                    <button
                        className="text-black text-2xl p-2 focus:outline-none lg:hidden" // Masqué sur les écrans md: et plus
                        onClick={() => setMenuOpen(!menuOpen)} // Toggle menu
                    >
                        <i className={`bi ${menuOpen ? 'bi-x-lg' : 'bi-list'}`}></i>
                    </button>
                </div>

                <div className='lg:block hidden'>
                    <h2 className="text-center text-gray-600 text-2xl font-serif ml-3">Espace Fournisseur</h2>
                </div>

                <div className='lg:hidden block'>
                    <Link
                        to="tableau-de-bord"
                        className="flex justify-center"
                        onClick={() => handleLinkClick("/tableau-de-bord")}
                    >
                        <img
                            src={Logo}
                            className="w-14 h-14 rounded-full"
                        />
                    </Link>
                </div>
                <div className="items-center flex-grow justify-center w-max space-x-4 lg:block hidden">
                    <div className="p-1 rounded-full">
                        <h2 className="text-center text-gray-600 text-2xl font-serif ml-3">La meilleure plateforme de vente de produits endogènes</h2>
                    </div>
                </div>
                <div className="flex items-center">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                        <div className="w-8 rounded-full">
                            <img
                                alt="User Avatar"
                                src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"
                            />
                        </div>
                    </div>
                </div>
            </header>

            <div className="flex flex-1 overflow-hidden">
                {/* Sidebar */}
                <aside
                    ref={sidebarRef} // Attache la référence
                    className={`fixed inset-y-0 left-0 w-64 bg-gray-100 text-gray-800 flex flex-col p-4 space-y-4 transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'
                        } transition-transform duration-300 lg:translate-x-0 lg:static lg:w-1/6`}
                >
                    <Link
                        to="tableau-de-bord"
                        className="mx-auto lg:block hidden"
                        onClick={() => handleLinkClick("/tableau-de-bord")}
                    >
                        <img
                            src={Logo}
                            className="md:max-w-36 md:h-36 rounded-full h-28"
                        />
                    </Link>
                    <h2 className="text-2xl text-center font-bold px-4 mb-6">
                        <span className="text-red-500">Consom<span className="text-green-500">Local</span></span>
                    </h2>
                    <nav className="flex flex-col gap-4">
                        <Link
                            to="tableau-de-bord"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/tableau-de-bord" ? "bg-green-200" : ""
                                }`}
                            onClick={() => handleLinkClick("/tableau-de-bord")}
                        >
                            <i className="text-xl bi bi-house"></i> <span>Tableau de bord</span>
                        </Link>
                        <Link
                            to="gestion-produits"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/gestion-produits" ? "bg-green-200" : ""
                                }`}
                            onClick={() => handleLinkClick("/gestion-produits")}
                        >
                            <i className="text-xl bi bi-cart"></i> <span>Gestion de Produits</span>
                        </Link>
                        <Link
                            to="ventes"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/ventes" ? "bg-green-200" : ""
                                }`}
                            onClick={() => handleLinkClick("/ventes")}
                        >
                            <i className="text-xl bi bi-arrow-repeat"></i> <span>Ventes</span>
                        </Link>
                        <Link
                            to="solde"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/solde" ? "bg-green-200" : ""
                                }`}
                            onClick={() => handleLinkClick("/solde")}
                        >
                            <i className="text-xl bi bi-wallet2"></i> <span>Accéder au solde</span>
                        </Link>
                        <Link
                            to="evaluations"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/evaluations" ? "bg-green-200" : ""
                                }`}
                            onClick={() => handleLinkClick("/evaluations")}
                        >
                            <i className="text-xl bi bi-chat-text"></i> <span>Consulter les évaluations</span>
                        </Link>
                        <Link
                            to="discuter-client"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/discuter-client" ? "bg-green-200" : ""
                                }`}
                            onClick={() => handleLinkClick("/discuter-client")}
                        >
                            <i className="text-xl bi bi-send-check"></i> <span>Discuter avec un client</span>
                        </Link>
                        <button
                            onClick={handleLogout}
                            className="w-full py-2 px-2 flex justify-center lg:px-4 text-xs lg:text-lg bg-red-600 items-center text-white font-semibold rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            <i className="text-md lg:text-xl bi bi-box-arrow-in-left mr-3 lg:mr-5"></i>
                            Se déconnecter
                        </button>
                    </nav>
                </aside>

                {/* Main Content */}
                <main className="flex-1 bg-gray-100 overflow-y-auto">
                    <div className="py-8 bg-white rounded-3xl border border-gray-300">
                        <Routes>
                            <Route path="tableau-de-bord" element={<TableauDeBord />} />
                            <Route path="gestion-produits" element={<GestionProduits />} />
                            <Route path="ventes" element={<GestionCommandes />} />
                            <Route path="solde" element={<Solde />} />
                            <Route path="evaluations" element={<Evaluation />} />
                            <Route path="discuter-client" element={<Message />} />
                        </Routes>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Dashboard;
