import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from "../../../../ConfigHost";

const ListeProduit = () => {
    const [produits, setProduits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8); // Nombre d'éléments par page

    useEffect(() => {
        // Récupérer la liste des produits
        axios
            .get(`${ConfigHost.API_URL}/api/produits/`)
            .then(response => {
                setProduits(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des produits', error);
            });
    }, []);

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = produits.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(produits.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">Liste des Produits</h2>
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-green-500 text-white">
                        <th className="py-2 px-4 text-left">Titre</th>
                        <th className="py-2 px-4 text-left">Description</th>
                        <th className="py-2 px-4 text-left">Prix</th>
                        <th className="py-2 px-4 text-left">Catégorie</th>
                        <th className="py-2 px-4 text-left">Fournisseur</th>
                        <th className="py-2 px-4 text-left">Photo</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map(produit => (
                        <tr key={produit.id} className="border-t">
                            <td className="py-2 px-4">{produit.titre}</td>
                            <td className="py-2 px-4">{produit.description}</td>
                            <td className="py-2 px-4">{produit.prix}</td>
                            <td className="py-2 px-4">{produit.categorie_contenu}</td>
                            <td className="py-2 px-4">{produit.fournisseur_username}</td>
                            <td className="py-2 px-4">
                                <img 
                                    src={produit.photo} 
                                    alt="Produit" 
                                    className="h-16 w-16 object-cover rounded"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="mt-4 flex justify-center">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 rounded ${
                            currentPage === index + 1
                                ? "bg-green-500 text-white"
                                : "bg-gray-200 hover:bg-green-300"
                        }`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ListeProduit;
