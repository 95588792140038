import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ConfigHost from '../../../ConfigHost';
import metier from '../../../assets/metier.jpg';
import { a, useNavigate } from 'react-router-dom';
import image from '../../../assets/img-5.png';
import file from '../../../assets/outils.png';
import dame from '../../../assets/dame.png';

const CategoriePage = () => {
    const { id } = useParams(); // ID de la catégorie actuelle
    const [produits, setProduits] = useState([]);
    const [categorie, setCategorie] = useState({});
    const [categories, setCategories] = useState([]); // Liste de toutes les catégories
    const navigate = useNavigate(); // Pour naviguer entre les catégories

    useEffect(() => {
        // Récupérer les produits par catégorie
        axios.get(`${ConfigHost.API_URL}/api/produits/?categorie=${id}`)
            .then(response => {
                setProduits(response.data);
            })
            .catch(error => {
                console.error("La récupération des produits a rencontré une erreur !", error);
            });

        // Récupérer les détails de la catégorie
        axios.get(`${ConfigHost.API_URL}/api/categories/${id}`)
            .then(response => {
                setCategorie(response.data);
            })
            .catch(error => {
                console.error("La récupération de la catégorie a rencontré une erreur !", error);
            });

        // Récupérer la liste complète des catégories
        axios.get(`${ConfigHost.API_URL}/api/categories/`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error("La récupération des catégories a rencontré une erreur !", error);
            });
    }, [id]);

    const navigateToCategory = (direction) => {
        // Trouver l'index de la catégorie actuelle
        const currentIndex = categories.findIndex(cat => cat.id === parseInt(id, 10));
        if (currentIndex !== -1) {
            // Calculer le nouvel index en fonction de la direction
            const newIndex = (currentIndex + direction + categories.length) % categories.length;
            navigate(`/categorie/${categories[newIndex].id}`);
        }
    };

    return (
        <div className="mt-28">
            <div className="hero flex justify-around bg-green-200 min-h-72 lg:w-9/12 lg:min-h-80 lg:mt-10 lg:mx-auto md:rounded-xl">
                <div>
                    <img
                        src={image}
                        className="md:max-w-sm rounded-lg max-h-60"
                        alt="Hero"
                    />
                </div>
                <div className="hero-content text-center">
                    <div className="max-w-md lg:max-w-2xl">
                        <h1 className="text-4xl xl:text-5xl lg:text-5xl md:text-5xl sm:text-5xl">
                            {categorie.contenu}
                        </h1>
                        <p className="text-gray-600 my-8">
                            Commander sur notre plateforme en toute sécurité
                        </p>
                    </div>
                </div>
                <div>
                    <img
                        src={file}
                        className="md:max-w-sm rounded-lg max-h-60"
                        alt="Hero"
                    />
                </div>
            </div>
            <div className="flex items-center gap-4 my-12">
                <h2 className="text-xl md:text-3xl font-bold ml-6 text-gray-500">
                    Les produits de la catégorie{" "}
                    <span className="text-green-600">{categorie.contenu}</span>
                </h2>
                <button
                    className="px-1 bg-gray-400 text-white rounded-full hover:bg-gray-500"
                    onClick={() => navigateToCategory(-1)}
                >
                    <i class="text-4xl bi bi-arrow-left-short"></i>
                </button>
                <button
                    className="px-1 bg-gray-400 text-white rounded-full hover:bg-gray-500"
                    onClick={() => navigateToCategory(1)}
                >
                    <i class="text-4xl bi bi-arrow-right-short"></i>
                </button>
            </div>
            <div className="w-full bg-gray-50 rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-3 mt-10 p-8">
                {produits.map(produit => (
                    <a key={produit.id} href={`/produit/${produit.id}`}>
                        <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                            <figure className="bg-gray-100 w-full h-3/5 rounded-xl">
                                <img src={produit.photo} alt={produit.titre} />
                            </figure>
                            <div className="px-2">
                                <h2 className="text-md card-title my-2">
                                    {produit.titre.length > 23
                                        ? `${produit.titre.slice(0, 23)}...`
                                        : produit.titre}
                                </h2>
                                <p className="text-sm text-gray-600 my-2">
                                    {produit.description.length > 66
                                        ? `${produit.description.slice(0, 66)}...`
                                        : produit.description}
                                </p>
                                <p
                                    className={`${produit.promo ? "line-through text-gray-500" : ""
                                        }`}
                                >
                                    {produit.prix} FCFA
                                </p>
                                {produit.promo && (
                                    <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                                        -{" "}
                                        {Math.round(
                                            100 - (100 * produit.promo) / produit.prix
                                        )}
                                        %
                                    </p>
                                )}
                                {produit.promo && (
                                    <p className="text-red-500">
                                        Promo : {produit.promo} FCFA
                                    </p>
                                )}
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            <div className="hero bg-base-200 my-10 lg:h-96 relative">
                <div className="absolute bottom-0 left-0 hidden md:block">
                    <img
                        src={dame}
                        className="max-w-44 rounded-lg max-h-60 opacity-75" />
                </div>
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <img
                        src={metier}
                        className="max-w-sm rounded-lg max-h-60 shadow-2xl" />
                    <div className='text-center mt-3 lg:w-4/6'>
                        <h1 className="text-3xl md:text-4xl font-bold">Vendez vos produits sur <span className='text-red-500'>Consom<span className='text-green-500'>Local</span ></span></h1>
                        <p className="py-6 text-gray-500">
                            Plongez dans l'univers fascinant du Burkina Faso avec notre sélection de produits endogènes. Nous vous offrons un accès direct à des créations artisanales uniques, des produits alimentaires savoureux et des objets de décoration authentiques. Chaque achat soutient les artisans locaux et contribue à préserver notre patrimoine culturel.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoriePage;