import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ListeCommandeManuelle from './ListeCommandeManuelle';

const AjoutCommandePhysique = ({ initialData, onCancel, onUpdate }) => {
    const [produits, setProduits] = useState([]);
    const [formData, setFormData] = useState({
        first_name: initialData?.first_name || '',
        last_name: initialData?.last_name || '',
        produit: initialData?.produit || '',
        quantite: initialData?.quantite || 1,
        prix: initialData?.prix || 0,
        total: initialData?.total || 0,
    });
    const [userId] = useState(localStorage.getItem('user_id'));
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProduits = async () => {
            try {
                const response = await axios.get(`${ConfigHost.API_URL}/api/produits/?fournisseur=${userId}`);
                setProduits(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des produits:', error);
            }
        };
        fetchProduits();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleProduitChange = (e) => {
        const produitId = parseInt(e.target.value, 10);
        const selectedProduit = produits.find((prod) => prod.id === produitId);
        if (selectedProduit) {
            setFormData((prevState) => ({
                ...prevState,
                produit: produitId,
                prix: selectedProduit.prix,
                total: prevState.quantite * selectedProduit.prix,
            }));
        }
    };

    const handleQuantiteChange = (e) => {
        const quantite = Math.max(1, parseInt(e.target.value, 10) || 1);
        setFormData((prevState) => ({
            ...prevState,
            quantite,
            total: quantite * prevState.prix,
        }));
    };

    const resetForm = () => {
        setFormData({
            first_name: '',
                    last_name: '',
                    produit: '',
                    quantite: 1,
                    prix: 0,
                    total: 0,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const newCommande = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            produit: formData.produit,
            quantite: formData.quantite,
            fournisseur: userId,
        };
    
        try {
            if (initialData) {
                // Mise à jour
                await axios.put(`${ConfigHost.API_URL}/api/commandes_physique/${initialData.id}/`, newCommande);
            } else {
                // Ajout
                await axios.post(`${ConfigHost.API_URL}/api/commandes_physique/`, newCommande);
                resetForm();
            }
    
            // Message de succès
            Swal.fire({
                title: 'Succès',
                text: "La commande a été ajoutée ou mise à jour avec succès!",
                icon: 'success',
                confirmButtonText: 'OK',
            }).then(() => {
                onUpdate?.(); // Vérifie si onUpdate est défini avant de l'appeler
                onCancel?.();
                <ListeCommandeManuelle/>
            });
        } catch (error) {
            console.error("Erreur lors de l'ajout ou de la mise à jour de la commande:", error);
        }
    };
    

    return (
        <div className="lg:px-6 max-w-4xl mx-auto py-6 bg-white shadow-lg rounded-lg">
            <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Ajouter une Commande Physique</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Prénom du client</label>
                        <input
                            type="text"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            placeholder="Entrez le prénom du client"
                            className="mt-1 p-3 w-full border border-gray-300 rounded-xl shadow-sm focus:ring-2 focus:ring-green-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Nom de famille du client</label>
                        <input
                            type="text"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            placeholder="Entrez le nom de famille du client"
                            className="mt-1 p-3 w-full border border-gray-300 rounded-xl shadow-sm focus:ring-2 focus:ring-green-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Choisir un produit</label>
                        <select
                            name="produit"
                            value={formData.produit}
                            onChange={handleProduitChange}
                            className="mt-1 p-3 w-full border border-gray-300 rounded-xl shadow-sm focus:ring-2 focus:ring-green-500"
                            required
                        >
                            <option value="">Sélectionner un produit</option>
                            {produits.map((produit) => (
                                <option key={produit.id} value={produit.id}>
                                    {produit.titre} - {produit.prix} FCFA
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Quantité</label>
                        <input
                            type="number"
                            name="quantite"
                            value={formData.quantite}
                            onChange={handleQuantiteChange}
                            placeholder="Entrez la quantité"
                            className="mt-1 p-3 w-full border border-gray-300 rounded-xl shadow-sm focus:ring-2 focus:ring-green-500"
                            min="1"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Total</label>
                        <input
                            type="text"
                            value={`${formData.total || 0} FCFA`}
                            disabled
                            className="mt-1 p-3 w-full border border-gray-300 rounded-xl shadow-sm focus:ring-2 focus:ring-green-500"
                        />
                    </div>
                </div>
                <div className="text-center">
                    <button
                        type="submit"
                        className="px-6 py-3 mt-4 bg-green-600 text-white font-bold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                    >
                        Ajouter la commande
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AjoutCommandePhysique;