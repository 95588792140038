import React, { useState } from 'react';
import ListeProduit from '../../../../components/pages/sous page/admin/ListeProduit';
import AjoutCategorie from "../../../../components/pages/sous page/admin/AjoutCategorie";

const GestionProduits = () => {
    const [activeTab, setActiveTab] = useState('ListeProduit');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'ListeProduit':
                return <ListeProduit />;
            case 'AjoutCategorie':
                return <AjoutCategorie />;
            default:
                return null;
        }
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold text-green-600 mb-4">Gestion des Produits</h1>
            <div className="mb-4">
                <button
                    onClick={() => setActiveTab('ListeProduit')}
                    className={`px-4 py-2 rounded-lg mr-2 ${activeTab === 'ListeProduit' ? 'bg-green-500 text-white' : 'bg-gray-200 hover:bg-green-300'}`}
                >
                    Liste des Produits
                </button>
                <button
                    onClick={() => setActiveTab('AjoutCategorie')}
                    className={`px-4 py-2 rounded-lg ${activeTab === 'AjoutCategorie' ? 'bg-green-500 text-white' : 'bg-gray-200 hover:bg-green-300'}`}
                >
                    Ajout Catégorie
                </button>
            </div>
            <div>
                {renderTabContent()}
            </div>
        </div>
    );
};

export default GestionProduits;
