import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';

const Solde = () => {
    const [totalSoldeVentePhysique, setTotalSoldeVentePhysique] = useState(0);
    const [totalSoldeVenteEnligne, setTotalSoldeVenteEnligne] = useState(0);


    useEffect(() => {
        const fetchData = async () => {

            try {
                const userId = localStorage.getItem('user_id'); // Assuming user ID is stored in local storage
                const response = await axios.get(`${ConfigHost.API_URL}/api/commandes_physique/?fournisseur=${userId}`);
                const commandes = response.data;

                // Calculer la somme des prix de tous les produits en s'assurant que commande.prix est bien un nombre
                const total = commandes.reduce((acc, commande) => {
                    const prix = Number(commande.total); // Conversion en nombre
                    return acc + (isNaN(prix) ? 0 : prix); // Ajouter 0 si prix n'est pas un nombre valide
                }, 0);
                setTotalSoldeVentePhysique(Math.floor(total)); // Conversion en entier


            } catch (error) {
                console.error('Erreur lors de la récupération des commandes:', error);
            }

            try {
                const userId = localStorage.getItem('user_id'); // Assuming user ID is stored in local storage
                const response = await axios.get(`${ConfigHost.API_URL}/api/commandes/?fournisseur_id=${userId}`);
                const commandes = response.data;

                // Calculer la somme des prix de tous les produits en s'assurant que commande.prix est bien un nombre
                const total = commandes.reduce((acc, commande) => {
                    const prix = Number(commande.prix); // Conversion en nombre
                    return acc + (isNaN(prix) ? 0 : prix); // Ajouter 0 si prix n'est pas un nombre valide
                }, 0);
                setTotalSoldeVenteEnligne(Math.floor(total)); // Conversion en entier


            } catch (error) {
                console.error('Erreur lors de la récupération des commandes:', error);
            }
        };

        fetchData();
    }, []);

    const totalSolde = totalSoldeVentePhysique + totalSoldeVenteEnligne;

    return (
        <div>
            <h2 className='text-xl mb-7 pl-6'>Accueil / <span className='text-gray-500'>Accéder au solde</span></h2>
            <div className="bg-white shadow-lg rounded-lg p-6 max-w-md mx-auto mt-10">
                <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">Solde Total</h1>
                <div className="bg-green-500 text-white text-4xl font-semibold p-4 rounded-lg text-center">
                    {totalSolde.toLocaleString('fr-FR')} FCFA
                </div>
                <p className="text-gray-600 mt-4 text-center">
                    Voici le montant total de vos ventes.
                </p>
            </div>
            <div className="flex justify-center w-12/12">
                <div className="bg-white shadow-lg rounded-lg p-6 max-w-md mx-auto mt-10">
                    <h1 className="text-xl font-serif text-gray-800 mb-4 text-center">Montant de la vente physique</h1>
                    <div className="bg-green-500 text-white text-4xl font-semibold p-4 rounded-lg text-center">
                        {totalSoldeVentePhysique.toLocaleString('fr-FR')} FCFA
                    </div>
                </div>
                <div className="bg-white shadow-lg rounded-lg p-6 max-w-md mx-auto mt-10">
                    <h1 className="text-xl font-serif text-gray-800 mb-4 text-center">Montant de la vente en ligne</h1>
                    <div className="bg-green-500 text-white text-4xl font-semibold p-4 rounded-lg text-center">
                        {totalSoldeVenteEnligne.toLocaleString('fr-FR')} FCFA
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Solde;
