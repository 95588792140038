import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import ConfigHost from '../../src/ConfigHost';

const CarteBancaire = ({ isOpen, onClose, totalAmount, cart }) => {
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    if (!isOpen && !showSuccessMessage) return null;

    const handleConfirm = async () => {
        const user_id = localStorage.getItem('user_id');
        const user_email = localStorage.getItem('user_email');
        const username = localStorage.getItem('username');

        try {
            // Traiter chaque élément du panier
            for (const item of cart) {
                // 1. Envoi de la requête pour créer la commande
                const orderResponse = await axios.post(`${ConfigHost.API_URL}/api/commandes/`, {
                    client: user_id,
                    produit: item.id,
                    quantite: item.quantity,
                });
                console.log('Réponse de la commande:', orderResponse.data);

                // Récupération de l'ID de la commande créée
                const orderId = orderResponse.data.id;

                // 2. Envoi de la requête pour créer le paiement
                const paymentResponse = await axios.post(`${ConfigHost.API_URL}/api/paiement/`, {
                    commande: orderId,
                    montant: totalAmount,
                    moyen_paiement: 'Carte Bancaire',
                });

                console.log('Réponse du paiement:', paymentResponse.data);

                // Vérifier la réponse du paiement
                if (paymentResponse.status !== 201) {
                    // Gérer les erreurs de paiement
                    console.error('Erreur lors du paiement: Status -', paymentResponse.status, 'Données -', paymentResponse.data);
                    throw new Error('Erreur lors du paiement');
                }
            }

            // Envoi de l'email de confirmation
            const emailResponse = await axios.post(`${ConfigHost.API_URL}/api/send-confirmation-email/`, {
                email: user_email,
                total_amount: totalAmount,
                username: username,
            });

            if (emailResponse.status === 200) {
                // Si l'email a été envoyé avec succès
                setShowSuccessMessage(true);
                setTimeout(() => onClose(), 2000);
            } else {
                throw new Error('Erreur lors de l\'envoi de l\'email');
            }

        } catch (error) {
            console.error('Erreur:', error.message || error);
        }
    };

    const handleCloseSuccess = () => {
        setShowSuccessMessage(false);
        onClose();
    };

    return ReactDOM.createPortal(
        <>
            <AnimatePresence>
                {isOpen && !showSuccessMessage && (
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white w-10/12 h-2/3 md:w-1/4 md:h-3/4 p-6 rounded-lg shadow-lg relative"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                        >
                            <button
                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                                onClick={onClose}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                            <h2 className="text-xl font-bold mb-4 text-center">Confirmation de la commande</h2>
                            <div className="mb-4">
                                <p className="text-sm text-center text-gray-600">Veuillez entrer les détails de votre carte bancaire pour finaliser le paiement.</p>
                            </div>
                            <div className="space-y-4 mb-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Numéro de carte</label>
                                    <input
                                        type="text"
                                        value={cardNumber}
                                        onChange={(e) => setCardNumber(e.target.value)}
                                        placeholder="Entrez le numéro de carte"
                                        className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </div>
                                <div className="flex space-x-4">
                                    <div className="flex-1">
                                        <label className="block text-sm font-medium text-gray-700">Date d'expiration</label>
                                        <input
                                            type="text"
                                            value={expiryDate}
                                            onChange={(e) => setExpiryDate(e.target.value)}
                                            placeholder="MM/AA"
                                            className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            required
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <label className="block text-sm font-medium text-gray-700">CVV</label>
                                        <input
                                            type="text"
                                            value={cvv}
                                            onChange={(e) => setCvv(e.target.value)}
                                            placeholder="XXX"
                                            className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="absolute bottom-4 left-4 right-4">
                                <button
                                    onClick={handleConfirm}
                                    className="bg-blue-500 w-full p-3 text-white rounded-lg"
                                >
                                    Continuer
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {showSuccessMessage && (
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white w-10/12 md:w-1/4 p-4 rounded-lg shadow-lg"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                        >
                            <h2 className="text-xl font-bold text-green-600">Succès</h2>
                            <p className="mt-2 text-center">Votre paiement a été validé avec succès. Un email sera envoyé à votre boîte email afin de garantir la traçabilité de votre paiement.</p>
                            <div className="flex justify-center mt-4">
                                <button
                                    onClick={handleCloseSuccess}
                                    className="bg-green-500 text-white px-4 py-2 rounded"
                                >
                                    OK
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>,
        document.body
    );
};

export default CarteBancaire;
