import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Routes, Route, Link, useLocation } from 'react-router-dom';
import TableauDeBordAdmin from '../../sous page/admin/TableauDeBordAdmin';
import GestionClients from '../../sous page/admin/GestionClients';
import GestionFournisseurs from '../../sous page/admin/GestionFournisseurs';
import GestionProduits from '../../sous page/admin/GestionProduits';
import GestionSolde from '../../sous page/admin/GestionSolde';
import Logo from '../../..//../assets/logo.png';

const Admin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [menuOpen, setMenuOpen] = useState(false);
    const sidebarRef = useRef(null);
    const username = localStorage.getItem('username');
    const [message, setMessage] = useState("");

    const handleLogout = () => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir vous deconnecter ?");
        if (!confirmation) return;

        try {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('username');
            localStorage.removeItem('user_type');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_email');
            navigate('/');
        } catch (error) {
            setMessage("Erreur lors de la deconnection.");
        }
        
    };

    const handleLinkClick = (path) => {
        setActiveLink(path);
        setMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="flex flex-col h-screen overflow-hidden">
            {/* Header */}
            <header className="h-16 bg-gray-100 text-white flex items-center justify-between p-4">
                <div className='lg:hidden'>
                    <button
                        className="text-black text-2xl p-2 focus:outline-none lg:hidden"
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <i className={`bi ${menuOpen ? 'bi-x-lg' : 'bi-list'}`}></i>
                    </button>
                </div>

                <div className="text-center">
                    <h2 className="text-center text-gray-600 text-xl lg:text-2xl font-serif ml-3">Espace Administrateur</h2>
                </div>

                <div className="lg:hidden block">
                    <Link
                        to="tableau-de-bord"
                        className="flex justify-center"
                        onClick={() => handleLinkClick("/tableau-de-bord")}
                    >
                        
                    </Link>
                </div>

                <div className="lg:block hidden">
                    <h2 className="text-center text-gray-600 text-2xl font-serif">La meilleure plateforme de gestion</h2>
                </div>

                <div className="flex items-center">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                        <div className="w-8 rounded-full">
                            <img
                                alt="User Avatar"
                                src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"
                            />
                        </div>
                    </div>
                </div>
            </header>

            <div className="flex flex-1 overflow-hidden">
                {/* Sidebar */}
                <aside
                    ref={sidebarRef}
                    className={`fixed inset-y-0 left-0 w-64 bg-gray-100 text-gray-800 flex flex-col p-4 space-y-4 transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 lg:translate-x-0 lg:static lg:w-1/6`}
                >
                    <h2 className="text-2xl text-center font-bold px-4 my-10">
                        <span className="text-red-500">Consom<span className="text-green-500">Local</span></span>
                    </h2>
                    <nav className="flex flex-col gap-4">
                        <Link
                            to="tableau-de-bord"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/tableau-de-bord" ? "bg-green-200" : ""}`}
                            onClick={() => handleLinkClick("/tableau-de-bord")}
                        >
                            <i className="text-xl bi bi-house"></i> <span>Tableau de bord</span>
                        </Link>
                        <Link
                            to="gestion-clients"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/gestion-clients" ? "bg-green-200" : ""}`}
                            onClick={() => handleLinkClick("/gestion-clients")}
                        >
                            <i className="text-xl bi bi-people"></i> <span>Gestion Client</span>
                        </Link>
                        <Link
                            to="gestion-fournisseurs"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/gestion-fournisseurs" ? "bg-green-200" : ""}`}
                            onClick={() => handleLinkClick("/gestion-fournisseurs")}
                        >
                            <i className="text-xl bi bi-person-badge"></i> <span>Gestion Fournisseur</span>
                        </Link>
                        <Link
                            to="gestion-produits"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/gestion-produits" ? "bg-green-200" : ""}`}
                            onClick={() => handleLinkClick("/gestion-produits")}
                        >
                            <i className="text-xl bi bi-box-seam"></i> <span>Gestion Produit</span>
                        </Link>
                        <Link
                            to="gestion-solde"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/gestion-solde" ? "bg-green-200" : ""}`}
                            onClick={() => handleLinkClick("/gestion-solde")}
                        >
                            <i className="text-xl bi bi-wallet2"></i> <span>Solde</span>
                        </Link>
                        <button
                            onClick={handleLogout}
                            className="w-full py-2 px-2 flex justify-center lg:px-4 text-xs lg:text-lg bg-red-600 items-center text-white font-semibold rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            <i className="text-md lg:text-xl bi bi-box-arrow-in-left mr-3 lg:mr-5"></i>
                            Se déconnecter
                        </button>
                    </nav>
                </aside>

                {/* Main Content */}
                <main className="flex-1 bg-gray-100 overflow-y-auto">
                    <div className="py-8 bg-white rounded-3xl border border-gray-300">
                    <Routes>
                        <Route path="tableau-de-bord" element={<TableauDeBordAdmin/>} />
                        <Route path="gestion-clients" element={<GestionClients />} />
                        <Route path="gestion-fournisseurs" element={<GestionFournisseurs />} />
                        <Route path="gestion-produits" element={<GestionProduits />} />
                        <Route path="gestion-solde" element={<GestionSolde/>} />
                    </Routes>

                    </div>
                </main>
            </div>
        </div>
    );
};

export default Admin;
