import React, { useEffect, useState } from 'react';
import { useCart } from '../CartContext';
import { useNavigate } from 'react-router-dom';
import { a } from 'react-router-dom';
import Connexion from '../pages/sous page/seConnecter/Connexion';
import Formulaire from '../pages/sous page/inscription/Formulaire';
import Logo from '../../assets/logo.png';

const Navbar = () => {
    const { cart, setCart, removeFromCart } = useCart();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isShowPopup, setShowPopup] = useState(false);
    const [product, setProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    const { addToCart } = useCart();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);
    const subtotal = cart.reduce((acc, item) => acc + item.prix * item.quantity, 0);

    const handleRemoveItem = (id) => {
        removeFromCart(id);
    };

    const handlePaiement = () => {
        navigate('/commande');
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('username');
        localStorage.removeItem('user_type');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_email');
        navigate('/');
    };

    const handleIncrement = (itemId) => {
        setCart((prevCart) => {
            const updatedCart = prevCart.map((item) =>
                item.id === itemId
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            );
            // Sauvegarder le panier dans le localStorage
            localStorage.setItem(`cart_${localStorage.getItem('user_id')}`, JSON.stringify(updatedCart));
            return updatedCart;
        });
    };
    
    const handleDecrement = (itemId) => {
        setCart((prevCart) => {
            const updatedCart = prevCart.map((item) =>
                item.id === itemId && item.quantity > 1
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
            );
            // Sauvegarder le panier dans le localStorage
            localStorage.setItem(`cart_${localStorage.getItem('user_id')}`, JSON.stringify(updatedCart));
            return updatedCart;
        });
    };
    


    const isClient = localStorage.getItem('user_type') === 'client';

    return (
        <div className='mb-12 '>
            <div className="navbar bg-base-100 border border-gray-100 fixed top-0 left-0 w-full z-50">

                {/* Hamburger menu for mobile screens */}
                <div className="lg:hidden flex items-center">
                    <div className="dropdown">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h7" />
                            </svg>
                        </div>
                        <ul
                            tabIndex={0}
                            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                            <li>
                                <a href="/favorites" className='text-lg hover:text-green-600'>
                                    Accueil
                                </a>
                            </li>
                            <li>
                                <a href="/contact" className='text-lg hover:text-green-600'>
                                    Contact
                                </a>
                            </li>
                            <li>
                                <a href="/apropos" className='text-lg hover:text-green-600'>
                                    A propos
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://wa.me/22652627396?text=Bonjour!%20J'ai%20une%20question."
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='text-lg md:text-sm hover:text-green-600'
                                >
                                    <i className="text-green-600 bi bi-whatsapp"></i> +226 52 62 73 96
                                </a>
                            </li>
                            <li>
                                <a href="/favoris" className='text-lg hover:text-green-600'>
                                    <i className="bi bi-heart"></i> Favoris
                                </a>
                            </li>
                            <li>
                                <button onClick={openModal} className='border border-gray-300 text-lg hover:text-green-600'>
                                    <i className="bi bi-person"></i> Se connecter
                                </button>
                            </li>
                            <li>
                                <button onClick={openPopup}
                                    className='text-lg border border-green-600 rounded-lg text-green-600 hover:bg-green-300 hover:text-black'>
                                    <i className="bi bi-person-check-fill"></i> S'inscrire
                                </button>
                            </li>
                        </ul>
                        <Connexion isOpen={isModalOpen} onClose={closeModal} />
                        <Formulaire isOpen={isShowPopup} onClose={closePopup} />
                    </div>
                </div>
                <div className="flex-1 flex justify-center lg:justify-start">
                    <a
                        href="/"
                        className='btn btn-ghost text-2xl'>
                        <span className='text-red-500'>Consom<span className='text-green-500'>Local</span ></span>
                    </a>
                    <img
                        src={Logo}
                        className="md:max-w-sm h-11 rounded-full" />
                </div>

                {/* Menu for larger screens */}
                <div className="hidden lg:flex flex-grow items-center justify-end">
                    <ul className='flex gap-10 px-6 items-center'>
                        <li>
                            <a href="/" className='text-lg md:text-sm hover:text-green-600'>
                                Accueil
                            </a>
                        </li>
                        <li>
                            <a href="/contact" className='text-lg md:text-sm hover:text-green-600'>
                                Contact
                            </a>
                        </li>
                        <li>
                            <a href="/apropos" className='text-lg md:text-sm hover:text-green-600'>
                                A propos
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://wa.me/22652627396?text=Bonjour!%20J'ai%20une%20question."
                                target="_blank"
                                rel="noopener noreferrer"
                                className='text-lg md:text-sm hover:text-green-600'
                            >
                                <i className="text-green-600 bi bi-whatsapp"></i> +226 52 62 73 96
                            </a>
                        </li>
                        <li>
                            <a href="/favoris" className='text-lg md:text-sm hover:text-green-600'>
                                <i className="bi bi-heart"></i> Favoris
                            </a>
                        </li>
                        <li>
                            <button onClick={openModal}
                                className='text-lg md:text-sm hover:text-green-600'>
                                <i className="bi bi-person"></i> Se connecter
                            </button>
                        </li>
                        <li>
                            <button onClick={openPopup}
                                className='text-lg md:text-sm p-2 border border-green-600 rounded-lg text-green-600 hover:bg-green-300 hover:text-black'>
                                <i className="bi bi-person-check-fill"></i> S'inscrire
                            </button>
                        </li>
                    </ul>
                    <Connexion isOpen={isModalOpen} onClose={closeModal} />
                    <Formulaire isOpen={isShowPopup} onClose={closePopup} />
                </div>

                <div className="flex-none md:gap-10 md:ml-5">
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                            <div className="indicator">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                                <span className="text-white badge badge-sm indicator-item bg-green-500">{totalItems}</span>
                            </div>
                        </div>
                        <div
                            tabIndex={0}
                            className="card card-compact dropdown-content bg-base-100 border border-gray-300 z-[1] mt-3 shadow w-72 sm:w-72 md:w-80 lg:w-96 h-64 sm:h-72 md:h-80 lg:h-96">
                            <div className="card-body">
                                <span className="text-xl font-bold">Panier({totalItems})</span>
                                <span className="text-green-600">Sous total: {subtotal.toFixed(2)} FCFA</span>
                                <div className="overflow-y-auto max-h-32 sm:max-h-36 md:max-h-40 lg:max-h-40">
                                    {cart.map((item) => (
                                        <div key={item.id} className="flex justify-between items-center py-2 border-b">
                                            <span>{item.titre}</span>
                                            <span>Prix: {item.prix} FCFA</span>
                                            <div className="flex items-center">
                                                <button
                                                    onClick={() => handleDecrement(item.id)}
                                                    className="px-2 py-1 text-white bg-gray-400 hover:bg-gray-500 rounded-l">
                                                    -
                                                </button>
                                                <span className="px-3">{item.quantity}</span>
                                                <button
                                                    onClick={() => handleIncrement(item.id)}
                                                    className="px-2 py-1 text-white bg-green-500 hover:bg-green-600 rounded-r">
                                                    +
                                                </button>
                                            </div>
                                            <button
                                                onClick={() => handleRemoveItem(item.id)}
                                                className="text-red-500 hover:text-red-700 ml-2"
                                                aria-label="Supprimer">
                                                <i className="bi bi-trash"></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <button
                                    onClick={handlePaiement}
                                    className={`btn rounded-full mt-4 w-full text-white ${cart.length === 0
                                        ? 'bg-gray-400 cursor-not-allowed'
                                        : 'bg-green-500 hover:bg-green-600'
                                        }`}
                                    disabled={cart.length === 0}>
                                    Passer au paiement
                                </button>
                            </div>
                        </div>
                    </div>


                    {isClient && (
                        <div className="dropdown dropdown-end">
                            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                                <div className="w-8 md:w-8 rounded-full">
                                    <img
                                        alt="User Avatar"
                                        src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
                                </div>
                            </div>
                            <ul
                                tabIndex={0}
                                className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-1 shadow">
                                <li>
                                    <a href='/profil' className="text-lg justify-between">
                                        Profil
                                    </a>
                                </li>
                                <li>
                                    <button
                                        onClick={handleLogout}
                                        className="w-full py-2 px-4 mt-4 text-red-600 font-semibold rounded-lg shadow-sm hover:bg-red-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                    >
                                        Se déconnecter
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;
