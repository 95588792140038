import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfigHost from "../../../../ConfigHost";

const AjoutCategorie = () => {
    const [contenu, setContenu] = useState(""); // État pour le contenu de la catégorie
    const [message, setMessage] = useState(""); // État pour afficher un message
    const [categories, setCategories] = useState([]); // État pour stocker les catégories existantes
    const [editId, setEditId] = useState(null); // État pour identifier la catégorie en cours de modification

    // Fonction pour récupérer les catégories
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${ConfigHost.API_URL}/api/categories/`);
            setCategories(response.data); // Met à jour l'état avec les données reçues
        } catch (error) {
            console.error("Erreur lors de la récupération des catégories :", error);
        }
    };

    // Appelle fetchCategories au chargement du composant
    useEffect(() => {
        fetchCategories();
    }, []);

    // Fonction pour gérer la soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(""); // Réinitialise le message

        try {
            if (editId) {
                // Mise à jour de la catégorie existante
                const response = await axios.put(`${ConfigHost.API_URL}/api/categories/${editId}/`, {
                    contenu,
                });
                if (response.status === 200) {
                    setMessage("La catégorie a été mise à jour avec succès.");
                    setEditId(null); // Réinitialise le mode édition
                }
            } else {
                // Ajout d'une nouvelle catégorie
                const response = await axios.post(`${ConfigHost.API_URL}/api/categories/`, {
                    contenu,
                });
                if (response.status === 201) {
                    setMessage("La catégorie a été ajoutée avec succès.");
                }
            }
            setContenu(""); // Réinitialise le champ après succès
            fetchCategories(); // Recharge la liste des catégories
        } catch (error) {
            setMessage("Erreur lors de la soumission. Vérifiez vos données.");
            console.error("Erreur API :", error.response?.data || error.message);
        }
    };

    // Fonction pour démarrer la modification
    const handleEdit = (category) => {
        setEditId(category.id); // Définit l'ID de la catégorie à modifier
        setContenu(category.contenu); // Pré-remplit le champ avec le contenu existant
    };

    // Fonction pour annuler la modification
    const cancelEdit = () => {
        setEditId(null);
        setContenu("");
    };

    // Fonction pour supprimer une catégorie
    const handleDelete = async (id) => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cette catégorie ?");
        if (!confirmation) return;

        try {
            await axios.delete(`${ConfigHost.API_URL}/api/categories/${id}/`);
            setMessage("La catégorie a été supprimée avec succès.");
            fetchCategories(); // Recharge la liste après suppression
        } catch (error) {
            setMessage("Erreur lors de la suppression de la catégorie.");
            console.error("Erreur API :", error.response?.data || error.message);
        }
    };

    return (
        <div className="max-w-6xl mx-auto bg-gray-50 p-8 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6 text-center">Gestion des Catégories</h2>

            {/* Formulaire d'ajout/modification */}
            <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="text-xl font-semibold mb-4">
                    {editId ? "Modifier la Catégorie" : "Ajouter une Catégorie"}
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="contenu" className="block text-sm font-medium text-gray-700">
                            Nom de la catégorie
                        </label>
                        <input
                            type="text"
                            id="contenu"
                            name="contenu"
                            value={contenu}
                            onChange={(e) => setContenu(e.target.value)}
                            placeholder="Entrez le nom de la catégorie"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div className="flex space-x-4">
                        <button
                            type="submit"
                            className="w-full bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                            {editId ? "Modifier" : "Ajouter"}
                        </button>
                        {editId && (
                            <button
                                type="button"
                                onClick={cancelEdit}
                                className="w-full bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            >
                                Annuler
                            </button>
                        )}
                    </div>
                </form>
                {message && (
                    <p className={`mt-4 text-sm ${message.includes("succès") ? "text-green-500" : "text-red-500"}`}>
                        {message}
                    </p>
                )}
            </div>

            {/* Tableau des catégories */}
            <div className="mt-8 bg-white p-6 rounded-lg shadow">
                <h3 className="text-xl font-semibold mb-4">Liste des Catégories</h3>
                {categories.length > 0 ? (
                    <table className="min-w-full table-auto border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                                    #
                                </th>
                                <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                                    Nom de la Catégorie
                                </th>
                                <th className="border border-gray-300 px-4 py-2 text-center text-sm font-medium text-gray-700">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((category, index) => (
                                <tr key={category.id} className="odd:bg-white even:bg-gray-100">
                                    <td className="border border-gray-300 px-4 py-2 text-sm">{index + 1}</td>
                                    <td className="border border-gray-300 px-4 py-2 text-sm">{category.contenu}</td>
                                    <td className="border border-gray-300 px-4 py-2 text-center">
                                        <button
                                            className="text-blue-500 hover:text-blue-700 mx-2"
                                            onClick={() => handleEdit(category)}
                                        >
                                            <i className="bi bi-pencil"></i>
                                        </button>
                                        <button
                                            className="text-red-500 hover:text-red-700 mx-2"
                                            onClick={() => handleDelete(category.id)}
                                        >
                                            <i className="bi bi-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-gray-500">Aucune catégorie disponible.</p>
                )}
            </div>
        </div>
    );
};

export default AjoutCategorie;
