import React from 'react';

const Propos = () => {
  return (
    <div className="w-11/12 mx-auto p-6 bg-white rounded-lg shadow-md my-16">
      <h1 className="text-3xl font-bold mb-6 text-center">À Propos</h1>
      
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Notre Mission</h2>
        <div className="text-gray-700">
          <p className="mb-4">
            Bienvenue sur notre plateforme dédiée à la vente de produits endogènes. 
            Nous croyons fermement que la richesse de notre culture locale mérite d'être 
            valorisée et partagée. Notre mission est de promouvoir les produits endogènes 
            de notre région, en soutenant les artisans et les producteurs locaux tout en 
            offrant à nos clients des produits authentiques et de qualité.
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Ce Que Nous Faisons</h2>
        <div className="text-gray-700">
          <p className="mb-4">
            Notre application web et mobile permet aux utilisateurs de découvrir et d'acheter 
            une large gamme de produits endogènes, allant des aliments traditionnels aux 
            produits artisanaux. En collaborant directement avec les producteurs locaux, nous 
            nous assurons que chaque produit respecte les normes de qualité et d'authenticité.
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Pourquoi Choisir Nos Produits ?</h2>
        <div className="text-gray-700">
          <ul className="list-disc list-inside mb-4">
            <li><strong>Qualité Supérieure :</strong> Chaque produit proposé sur notre plateforme est soigneusement sélectionné pour sa qualité, son authenticité et sa contribution à notre patrimoine culturel.</li>
            <li><strong>Soutien aux Artisans Locaux :</strong> En achetant nos produits, vous contribuez directement au développement économique de notre communauté. Nous travaillons main dans la main avec des artisans locaux pour leur offrir une visibilité et un soutien financier.</li>
            <li><strong>Durabilité :</strong> Nous nous engageons à promouvoir des pratiques durables et respectueuses de l'environnement. Nos produits sont souvent issus de méthodes de production traditionnelles, préservant ainsi notre écosystème.</li>
          </ul>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Notre Vision</h2>
        <div className="text-gray-700">
          <p className="mb-4">
            Nous aspirons à devenir la référence en matière de produits endogènes en ligne. 
            Notre plateforme est conçue pour offrir une expérience utilisateur fluide et 
            intuitive, où chaque achat est une opportunité de découvrir et d’apprécier les 
            richesses de notre culture locale.
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Rejoignez-Nous</h2>
        <div className="text-gray-700">
          <p className="mb-4">
            Que vous soyez un passionné de culture locale, un gourmet à la recherche de saveurs 
            authentiques ou un simple curieux désireux d'en apprendre davantage sur les produits 
            endogènes, nous vous invitons à explorer notre plateforme. Ensemble, faisons vivre 
            notre patrimoine et soutenons nos artisans locaux.
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contactez-Nous</h2>
        <div className="text-gray-700">
          <p>
            Si vous avez des questions ou des suggestions, n'hésitez pas à nous contacter. 
            Votre avis est précieux pour nous aider à améliorer nos services et à mieux répondre 
            à vos attentes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Propos;
