import React, { useState } from 'react';

const EspaceNote = ({ notes }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentNotes = notes.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(notes.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Notes</h3>
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-green-200">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Client
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Produit
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Note
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Date
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {currentNotes.length > 0 ? (
                        currentNotes.map((note) => (
                            <tr key={note.id} className="hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap">{note.client_username}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{note.produit_titre}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{note.note_value}</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {new Date(note.date_avis).toLocaleDateString()}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="px-6 py-4 text-center text-gray-600">
                                Aucune note disponible
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="flex justify-between mt-4">
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50"
                >
                    Précédent
                </button>
                <span className="text-gray-600">
                    Page {currentPage} sur {totalPages}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50"
                >
                    Suivant
                </button>
            </div>
        </div>
    );
};

export default EspaceNote;
