import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';
import AjoutCommandePhysique from '../../../pages/sous page/admin/AjoutCommandePhysique';
import ListeCommandeManuelle from './ListeCommandeManuelle';
import ListeCommandeGlobale from './ListeCommandeGlobale';

const GestionCommandes = () => {
    const [commandesManuelles, setCommandesManuelles] = useState([]);
    const [commandesGlobales, setCommandesGlobales] = useState([]);
    const [activeTab, setActiveTab] = useState('listes_manuelles');
    const [userId] = useState(localStorage.getItem('user_id'));

    useEffect(() => {
        fetchCommandesManuelles();
        fetchCommandesGlobales();
    }, [userId]);

    const fetchCommandesManuelles = async () => {
        try {
            const response = await axios.get(`${ConfigHost.API_URL}/api/commandes/?type=manuelle&fournisseur=${userId}`);
            setCommandesManuelles(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des commandes manuelles:', error);
        }
    };

    const fetchCommandesGlobales = async () => {
        try {
            const response = await axios.get(`${ConfigHost.API_URL}/api/commandes/?fournisseur=${userId}`);
            setCommandesGlobales(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des commandes globales:', error);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-xl mb-7">Accueil / <span className="text-gray-500">Gestion des Commandes</span></h2>
            <div className="flex mb-6">
                <button
                    onClick={() => handleTabChange('ajout')}
                    className={`text-xs lg:text-lg mr-4 px-4 py-2 ${activeTab === 'ajout' ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-lg`}
                >
                    Ajouter une commande
                </button>
                <button
                    onClick={() => handleTabChange('listes_manuelles')}
                    className={`text-xs lg:text-lg mr-4 px-4 py-2 ${activeTab === 'listes_manuelles' ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-lg`}
                >
                    Liste des commandes physiques
                </button>
                <button
                    onClick={() => handleTabChange('listes_globales')}
                    className={`text-xs lg:text-lg px-4 py-2 ${activeTab === 'listes_globales' ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-lg`}
                >
                    Liste des commandes en ligne
                </button>
            </div>
            <div className="">
                {activeTab === 'ajout' && (
                    <AjoutCommandePhysique
                        fetchCommandesManuelles={fetchCommandesManuelles}
                    />
                )}
                {activeTab === 'listes_manuelles' && (
                    <ListeCommandeManuelle
                        commandes={commandesManuelles}
                        fetchCommandesManuelles={fetchCommandesManuelles}
                    />
                )}
                {activeTab === 'listes_globales' && (
                    <ListeCommandeGlobale
                        commandes={commandesGlobales}
                        fetchCommandesGlobales={fetchCommandesGlobales}
                    />
                )}
            </div>
        </div>
    );
};

export default GestionCommandes;
