import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';

const GestionSolde = () => {
    const [solde, setSolde] = useState({
        ventePhysique: 0,
        venteEnligne: 0,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fonction pour récupérer et calculer le total des commandes
    const fetchData = async (url, key) => {
        try {
            const response = await axios.get(url);
            const commandes = response.data;
            const total = commandes.reduce((acc, commande) => {
                const prix = Number(commande[key]); // Conversion en nombre
                return acc + (isNaN(prix) ? 0 : prix); // Ajouter 0 si prix n'est pas un nombre valide
            }, 0);
            return Math.floor(total); // Retourner le total arrondi
        } catch (error) {
            console.error('Erreur lors de la récupération des commandes:', error);
            throw error;
        }
    };

    useEffect(() => {
        const fetchDataAndSetState = async () => {
            setLoading(true);
            setError(null);  // Réinitialiser l'erreur avant chaque nouvelle tentative

            try {
                const [physique, enligne] = await Promise.all([
                    fetchData(`${ConfigHost.API_URL}/api/commandes_physique/`, 'total'),
                    fetchData(`${ConfigHost.API_URL}/api/commandes/`, 'prix'),
                ]);
                setSolde({ ventePhysique: physique, venteEnligne: enligne });
            } catch (err) {
                setError('Impossible de récupérer les données');
            } finally {
                setLoading(false);
            }
        };

        fetchDataAndSetState();
    }, []);

    const totalSolde = solde.ventePhysique + solde.venteEnligne;

    if (loading) {
        return (
            <div className="text-center p-5">
                <p>Chargement...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center p-5 text-red-500">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div>
            <h2 className="text-xl mb-7 pl-6">Accueil / <span className="text-gray-500">Accéder au solde</span></h2>

            {/* Solde Total */}
            <div className="bg-white shadow-lg rounded-lg p-6 max-w-lg mx-auto mt-10">
                <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">Solde Total</h1>
                <div className="bg-green-500 text-white text-4xl font-semibold p-4 rounded-lg text-center">
                    {totalSolde.toLocaleString('fr-FR')} FCFA
                </div>
                <p className="text-gray-600 mt-4 text-center">Voici le montant total de vos ventes.</p>
            </div>

            {/* Ventes Physique et En Ligne */}
            <div className="flex justify-between gap-6 mt-10 px-6">
                {/* Vente Physique */}
                <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-sm">
                    <h1 className="text-xl font-serif text-gray-800 mb-4 text-center">Montant de la vente physique</h1>
                    <div className="bg-green-500 text-white text-3xl font-semibold p-4 rounded-lg text-center">
                        {solde.ventePhysique.toLocaleString('fr-FR')} FCFA
                    </div>
                </div>
                {/* Vente en Ligne */}
                <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-sm">
                    <h1 className="text-xl font-serif text-gray-800 mb-4 text-center">Montant de la vente en ligne</h1>
                    <div className="bg-green-500 text-white text-3xl font-semibold p-4 rounded-lg text-center">
                        {solde.venteEnligne.toLocaleString('fr-FR')} FCFA
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GestionSolde;
