import React, { useState } from 'react';
import '../../../../css/ListeProduit.css';

const ListeProduit = ({ produits, handleEdit, handleDelete }) => {
    const [expandedProduct, setExpandedProduct] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);

    // Nombre d'éléments par page
    const itemsPerPage = 8;

    // Filtrer les produits par titre
    const filteredProducts = produits.filter((produit) =>
        produit.titre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination: découper les produits en pages
    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    // Gestion du changement de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const toggleExpand = (productId) => {
        setExpandedProduct(expandedProduct === productId ? null : productId);
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    };

    const handleConfirmDelete = () => {
        if (productToDelete) {
            handleDelete(productToDelete); // Appel de la fonction de suppression
            setShowDeletePopup(false); // Fermer le popup après suppression
            setProductToDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setShowDeletePopup(false);
        setProductToDelete(null);
    };

    return (
        <div>
            <h3 className="text-2xl font-semibold mb-4">Liste des produits</h3>
            
            {/* Champ de filtrage par titre */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Filtrer par titre..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="px-4 py-2 border text-black border-gray-400 rounded-md"
                />
            </div>

            {/* Tableau des produits */}
            <table className="min-w-full divide-y divide-gray-200">
                <thead className=''>
                    <tr className='bg-green-200'>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Photo</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Titre</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Description</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Prix</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Promo</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Catégorie</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Actions</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {currentProducts.map(produit => (
                        <tr key={produit.id} className="hover:bg-gray-100">
                            <td className="px-6 py-4 whitespace-nowrap">
                                <img
                                    src={produit.photo}
                                    alt={produit.titre}
                                    className="w-16 h-16 object-cover rounded-md"
                                />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">{produit.titre}</td>
                            <td className="px-6 py-4 whitespace-normal max-w-xs">
                                <div className={`description-container ${expandedProduct === produit.id ? 'expanded' : 'collapsed'}`}>
                                    {expandedProduct === produit.id ? produit.description : truncateText(produit.description, 50)}
                                </div>
                                <button
                                    onClick={() => toggleExpand(produit.id)}
                                    className="text-green-600 hover:text-green-900 ml-2"
                                >
                                    {expandedProduct === produit.id ? <i className="bi bi-caret-up-fill"></i> : <i className="bi bi-caret-down-fill"></i>}
                                </button>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">{produit.prix} FCFA</td>
                            <td className="px-6 py-4 whitespace-nowrap">{produit.promo ? `${produit.promo} FCFA` : 'Aucune'}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{produit.categorie_contenu}</td>
                            <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-4">
                                <button
                                    onClick={() => handleEdit(produit.id)}
                                    className="text-green-600 hover:text-green-900"
                                >
                                    <i className="bi bi-pencil"></i>
                                </button>
                                <button
                                    onClick={() => {
                                        setProductToDelete(produit.id);
                                        setShowDeletePopup(true);
                                    }}
                                    className="text-red-600 hover:text-red-900"
                                >
                                    <i className="bi bi-trash"></i>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            <div className="mt-4 flex justify-center">
                <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-green-600 text-white rounded-l"
                >
                    Précédent
                </button>
                <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage * itemsPerPage >= filteredProducts.length}
                    className="px-4 py-2 bg-green-600 text-white rounded-r"
                >
                    Suivant
                </button>
            </div>

            {/* Popup de confirmation de suppression */}
            {showDeletePopup && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-md shadow-lg w-1/3">
                        <h4 className="text-xl font-semibold mb-4">Confirmer la suppression</h4>
                        <p>Êtes-vous sûr de vouloir supprimer ce produit ?</p>
                        <div className="mt-4 flex justify-end space-x-4">
                            <button
                                onClick={handleCancelDelete}
                                className="px-4 py-2 bg-gray-500 text-white rounded"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={handleConfirmDelete}
                                className="px-4 py-2 bg-red-600 text-white rounded"
                            >
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListeProduit;
