import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';

const LesAchats = () => {
    const [userId] = useState(localStorage.getItem('user_id'));
    const [commandes, setCommandes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    // États pour le filtrage par date
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const [dateError, setDateError] = useState(null);

    useEffect(() => {
        const fetchCommandes = async () => {
            setLoading(true);
            setError(null);

            try {
                // Paramètres de filtrage
                const params = {
                    fournisseur_id: userId,
                    ...(startDate && { date_interval_after: startDate }),
                    ...(endDate && { date_interval_before: endDate }),
                };

                const response = await axios.get(`${ConfigHost.API_URL}/api/commandes/`, { params });
                setCommandes(response.data);
            } catch (error) {
                setError('Erreur lors de la récupération des commandes');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCommandes();
    }, [userId, startDate, endDate]);

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCommandes = commandes.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(commandes.length / itemsPerPage);

    // Gestion du formulaire de filtrage
    const handleFilterSubmit = () => {
        // Vérifier que la date de début est avant la date de fin
        if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
            setDateError('La date de début ne peut pas être après la date de fin.');
        } else {
            setIsFilterPopupOpen(false); // Fermer le popup après filtrage
            setDateError(null); // Réinitialiser l'erreur
        }
    };

    // Fonction pour naviguer entre les pages
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) return <p>Chargement...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="py-6">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Mes produits vendus</h3>

            {/* Bouton pour ouvrir le popup de filtrage */}
            <div className="mb-4">
                <button
                    className="bg-green-600 text-white px-4 py-2 rounded shadow hover:bg-green-700"
                    onClick={() => setIsFilterPopupOpen(true)}
                >
                    Filtrer
                </button>
            </div>

            {/* Tableau des commandes */}
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white divide-y divide-gray-200 shadow-md rounded-lg">
                    <thead className="bg-green-600 text-white">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Client</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Produit</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Quantité</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Prix</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Total</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Heure</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {currentCommandes.map((commande) => (
                            <tr key={commande.id} className="hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{commande.client_username}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.produit_titre}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.quantite}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.prix} FCFA</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.total} FCFA</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(commande.created_at).toLocaleDateString()}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(commande.created_at).toLocaleTimeString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-4">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`mx-1 px-3 py-1 border rounded ${
                            currentPage === index + 1
                                ? 'bg-green-600 text-white'
                                : 'bg-white text-gray-800 hover:bg-green-200'
                        }`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>

            {/* Popup de filtrage */}
            {isFilterPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-md w-1/3">
                        <h4 className="text-lg font-semibold mb-4">Filtrer par date</h4>
                        <label className="block mb-2 text-sm font-medium text-gray-700">
                            Date de début :
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="block w-full px-3 py-2 mt-1 border rounded shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                        </label>
                        <label className="block mb-4 text-sm font-medium text-gray-700">
                            Date de fin :
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="block w-full px-3 py-2 mt-1 border rounded shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                        </label>

                        {/* Message d'erreur */}
                        {dateError && <p className="text-red-600 text-sm">{dateError}</p>}

                        <div className="flex justify-end space-x-2">
                            <button
                                className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500"
                                onClick={() => setIsFilterPopupOpen(false)}
                            >
                                Annuler
                            </button>
                            <button
                                className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
                                onClick={handleFilterSubmit}
                            >
                                Appliquer
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LesAchats;
