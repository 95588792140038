import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { a, useNavigate } from 'react-router-dom';
import photo from '../../../assets/photo.jpeg';
import image from '../../../assets/image.png';
import ConfigHost from '../../../ConfigHost';
import '../../../../src/css/ListeProduit.css';
import yennega from '../../../assets/yennega.png';
import sac from '../../../assets/sac.png';

const Layout = () => {
    const [produits, setProduits] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filtrage, setFiltrage] = useState([]);
    const [filtrageMode, setFiltrageMode] = useState([]);
    const [randomProduits, setRandomProduits] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const [currentPageMode, setCurrentPageMode] = useState(1);
    const itemsPerPageMode = 6;
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Récupérer les produits initiaux et lancer un intervalle pour mise à jour aléatoire
        const fetchProducts = () => {
            axios.get(`${ConfigHost.API_URL}/api/produits/`)
                .then(response => {
                    setProduits(response.data);

                    // Sélectionner 5 produits aléatoires
                    const shuffledProduits = response.data.sort(() => 0.6 - Math.random());
                    setRandomProduits(shuffledProduits.slice(0, 6)); // Prendre 5 produits
                })
                .catch(error => {
                    console.error('La récupération des produits a rencontré une erreur !', error);
                });
        };

        fetchProducts(); // Appeler une première fois la récupération des produits

        // Mettre à jour les produits toutes les 5 secondes
        const intervalId = setInterval(() => {
            fetchProducts();
        }, 20000);

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        // Récupérer les catégories
        axios.get(`${ConfigHost.API_URL}/api/categories/`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('La récupération des catégories a rencontré une erreur !', error);
            });

        // Récupérer les produits de la catégorie Artisanat
        axios.get(`${ConfigHost.API_URL}/api/produits/?categorie_contenu=Artisanat`)
            .then(response => {
                setFiltrage(response.data);
            })
            .catch(error => {
                console.error('La récupération des produits filtrés Artisanat a rencontré une erreur !', error);
            });

        // Récupérer les produits de la catégorie Mode
        axios.get(`${ConfigHost.API_URL}/api/produits/?categorie_contenu=Mode`)
            .then(response => {
                setFiltrageMode(response.data);
            })
            .catch(error => {
                console.error('La récupération des produits filtrés Mode a rencontré une erreur !', error);
            });
    }, []);

    // Fermer le pop-up quand on clique en dehors
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCategoryClick = (categorieId) => {
        navigate(`/categorie/${categorieId}`);
    };

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query.length > 1) {
            // Requête pour récupérer les produits correspondant à la saisie
            axios
                .get(`${ConfigHost.API_URL}/api/produits/?search=${query}`)
                .then((response) => {
                    setSuggestions(response.data);
                    setIsDropdownVisible(true); // Afficher la liste déroulante
                })
                .catch((error) => {
                    console.error("Erreur lors de la recherche des produits", error);
                });
        } else {
            setSuggestions([]);
            setIsDropdownVisible(false); // Masquer la liste déroulante si la saisie est trop courte
        }
    };

    const handleSuggestionClick = (produitTitre) => {
        navigate(`/recherche`, { state: { query: produitTitre } });
        setSearchQuery(''); // Réinitialiser la recherche
        setSuggestions([]); // Vider les suggestions
        setIsDropdownVisible(false); // Masquer la liste
    };

    const handleSearchSubmit = (produitTitre) => {
        if (produitTitre) {
            navigate(`/recherche/`, { state: { query: produitTitre } });
        } else {
            alert('Produit non trouvé');
        }
    };

    const highlightText = (text, query) => {
        const index = text.toLowerCase().indexOf(query.toLowerCase());
        if (index === -1) return text;

        const beforeMatch = text.slice(0, index);
        const match = text.slice(index, index + query.length);
        const afterMatch = text.slice(index + query.length);

        return (
            <>
                <span className="text-black mr-1">{beforeMatch}</span>
                <span className="text-gray-600">{match}</span>
                <span className="text-black">{afterMatch}</span>
            </>
        );
    };

    // Calculer les produits pour la page actuelle
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProduits = filtrage.slice(indexOfFirstItem, indexOfLastItem);

    // Fonction pour changer de page
    const handlePageChange = (direction) => {
        if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (direction === 'next' && indexOfLastItem < filtrage.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Calculer les produits pour la page actuelle
    const indexOfLastItemMode = currentPageMode * itemsPerPageMode;
    const indexOfFirstItemMode = indexOfLastItemMode - itemsPerPageMode;
    const currentProduitMode = filtrageMode.slice(indexOfFirstItemMode, indexOfLastItemMode);

    // Fonction pour changer de page
    const handlePageChangeMode = (direction) => {
        if (direction === 'prev' && currentPageMode > 1) {
            setCurrentPageMode(currentPageMode - 1);
        } else if (direction === 'next' && indexOfLastItemMode < filtrageMode.length) {
            setCurrentPageMode(currentPageMode + 1);
        }
    };


    const firstFiveProduitsMode = filtrageMode.slice(0, 6);
    const categorieNombre = categories.slice(0, 7);

    return (
        <div className='flex flex-col'>
            <div className="hero bg-base-200 min-h-60 lg:w-11/12 lg:min-h-72 lg:mt-10 lg:mx-auto md:rounded-3xl relative">
                <div className="absolute top-0 right-1/4 m-4 hidden lg:block">
                    <i className="text-6xl opacity-10 bi bi-flower1"></i>
                </div>
                <div className="absolute top-0 left-64 m-4 transform rotate-45 hidden lg:block">
                    <i className="text-6xl opacity-10 bi-gift-fill"></i>
                </div>
                <div className="absolute bottom-0 left-24 m-4 transform rotate-45 hidden lg:block">
                    <i className="text-6xl opacity-10 bi-qr-code"></i>
                </div>
                <div className="absolute bottom-0 right-32 m-4 hidden lg:block">
                    <i className="text-6xl opacity-10 bi bi-snow"></i>
                </div>
                <div className="hero-content flex justify-between items-center p-4 w-full">
                    <div className="hidden lg:block">
                        <img
                            src={sac}
                            className="rounded-lg w-60 h-auto opacity-75"
                            alt="Produit"
                        />
                    </div>
                    <div className="w-full lg:max-w-2xl mx-4">
                        <h1 className="text-center text-3xl md:text-4xl font-bold">
                            La vitrine des produits <span className="text-green-600">locaux</span> de la grande Afrique
                        </h1>
                        <p className="py-6 text-center text-gray-600">
                            Explorez notre plateforme dédiée à la richesse culturelle & artisanale de l'Afrique. Nous vous proposons une gamme variée de produits locaux allant des vêtements traditionnels en Faso Dan Fani aux huiles précieuses issues du karité...
                        </p>
                        <div className="mt-4 bg-green-200 rounded-3xl p-2 relative" ref={dropdownRef}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSearchSubmit(searchQuery);
                                }}
                                className="flex"
                            >
                                <input
                                    type="text"
                                    placeholder="Rechercher un produit"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="input lg:h-11 w-full bg-green-200"
                                />
                                <button type="submit" className="text-3xl bg-green-200 text-gray-500 lg:h-11">
                                    <i className="bi bi-search"></i>
                                </button>
                            </form>

                            {/* Liste des suggestions */}
                            {isDropdownVisible && (
                                <ul
                                    className="absolute bg-white w-full mt-2 shadow-lg rounded-lg z-50"
                                    style={{ maxHeight: '200px', overflowY: 'auto' }}
                                >
                                    {/* Ajouter le texte saisi en haut de la liste */}
                                    {searchQuery && (
                                        <li
                                            onClick={() => handleSuggestionClick(searchQuery)}
                                            className="px-4 py-2 cursor-pointer hover:bg-green-100 text-gray-600 flex items-center"
                                        >
                                            <span className="text-gray-500">{searchQuery}</span>
                                        </li>
                                    )}
                                    {/* Afficher les suggestions */}
                                    {suggestions.map((produit) => (
                                        <li
                                            key={produit.id}
                                            onClick={() => handleSuggestionClick(produit.titre)}
                                            className="px-4 py-2 cursor-pointer hover:bg-green-100 flex items-center"
                                        >
                                            {highlightText(produit.titre, searchQuery)}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="hidden lg:block">
                        <img
                            src={yennega}
                            className="rounded-lg w-60 h-auto opacity-75"
                            alt="Produit"
                        />
                    </div>
                </div>
            </div>
            <div className="border border-gray-300 rounded-xl grid grid-cols-3 md:grid-cols-4 lg:flex lg:mx-auto gap-8 my-3 p-4 md:p-4">
                <div className="dropdown">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h7" />
                        </svg>
                    </div>
                    <ul
                        tabIndex={0}
                        className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                        <li>
                            {categories.map(categorie => (
                                <button
                                    key={categorie.id}
                                    onClick={() => handleCategoryClick(categorie.id)}
                                    className='text-sm md:text-md text-gray-700'>
                                    {categorie.contenu}
                                </button>
                            ))}
                        </li>
                    </ul>
                </div>
                {categorieNombre.map(categorie => (
                    <button
                        key={categorie.id}
                        onClick={() => handleCategoryClick(categorie.id)}
                        className='hover:bg-green-100 hover:border-green-300 rounded-xl border border-gray-400 p-2 md:p-3 text-sm md:text-md text-gray-700'>
                        {categorie.contenu}
                    </button>
                ))}
            </div>
            <div className="w-full">
                <h1 className="text-gray-500 text-2xl font-bold md:text-3xl md:mx-10 md:my-1">Produits Populaires</h1>
                <div className="w-full rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 mt-1 p-8">
                    {randomProduits.map(produit => (
                        <a key={produit.id} href={`/produit/${produit.id}`}>
                            <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                                <figure className='bg-gray-100 w-full h-2/5 rounded-xl'>
                                    <img src={produit.photo} alt={produit.titre} />
                                </figure>
                                <div className="px-2">
                                    <h2 className="text-md card-title my-2">
                                        {produit.titre.length > 23 ? `${produit.titre.slice(0, 23)}...` : produit.titre}
                                    </h2>
                                    <p className='text-sm text-gray-600 my-2'>{produit.description.length > 66 ? `${produit.description.slice(0, 66)}...` : produit.description}

                                    </p>
                                    <p className={`${produit.promo ? 'line-through text-gray-500' : ''}`}>
                                        {produit.prix} FCFA
                                    </p>
                                    {produit.promo && (
                                        <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                                            - {Math.round(100 - (100 * produit.promo / produit.prix))}%
                                        </p>
                                    )}

                                    {produit.promo && (
                                        <p className="text-red-500">Promo : {produit.promo} FCFA</p>
                                    )}
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
            <div className="w-full flex justify-center">
                <div className="w-12/12 rounded-lg shadow-sm md:w-11/12">
                    <div className="rounded-t-lg border-b border-gray-300 bg-green-500 flex justify-between items-center px-4">
                        <h1 className="text-xl text-white py-4 font-bold">Categorie Artisanat</h1>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => handlePageChange('prev')}
                                disabled={currentPage === 1}
                                className={`px-1 bg-gray-300 rounded-full ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'}`}
                            >
                                <i className="text-3xl bi bi-arrow-left-short"></i>
                            </button>
                            <button
                                onClick={() => handlePageChange('next')}
                                disabled={indexOfLastItem >= filtrage.length}
                                className={`px-1 bg-gray-300 rounded-full ${indexOfLastItem >= filtrage.length ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'}`}
                            >
                                <i className="text-3xl bi bi-arrow-right-short"></i>
                            </button>
                        </div>
                    </div>
                    <div className="w-full rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-3 p-8">
                        {currentProduits.map(produit => (
                            <a key={produit.id} href={`/produit/${produit.id}`}>
                                <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                                    <figure className='bg-gray-100 w-full h-3/5 rounded-xl'>
                                        <img src={produit.photo} alt={produit.titre} />
                                    </figure>
                                    <div className="px-2">
                                        <h2 className="text-md card-title my-2">
                                            {produit.titre.length > 23 ? `${produit.titre.slice(0, 23)}...` : produit.titre}
                                        </h2>
                                        <p className='text-sm text-gray-600 my-2'>{produit.description.length > 66 ? `${produit.description.slice(0, 66)}...` : produit.description}</p>
                                        <p className={`${produit.promo ? 'line-through text-gray-500' : ''}`}>
                                            {produit.prix} FCFA
                                        </p>
                                        {produit.promo && (
                                            <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                                                - {Math.round(100 - (100 * produit.promo / produit.prix))}%
                                            </p>
                                        )}
                                        {produit.promo && (
                                            <p className="text-red-500">Promo : {produit.promo} FCFA</p>
                                        )}
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            <div className="w-full flex justify-center">
                <div className="w-full rounded-lg my-6 shadow-sm md:w-11/12">
                    <div className="rounded-t-lg border-b border-gray-300 bg-green-500 flex justify-between items-center px-4">
                        <h1 className="text-xl text-white py-4 font-bold">Categorie Mode</h1>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => handlePageChangeMode('prev')}
                                disabled={currentPageMode === 1}
                                className={`px-1 bg-gray-300 rounded-full ${currentPageMode === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'}`}
                            >
                                <i className="text-3xl bi bi-arrow-left-short"></i>
                            </button>
                            <button
                                onClick={() => handlePageChangeMode('next')}
                                disabled={indexOfLastItemMode >= filtrageMode.length}
                                className={`px-1 bg-gray-300 rounded-full ${indexOfLastItemMode >= filtrageMode.length ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'}`}
                            >
                                <i className="text-3xl bi bi-arrow-right-short"></i>
                            </button>
                        </div>
                    </div>
                    <div className="w-full rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-3 p-8">
                        {currentProduitMode.map(produit => (
                            <a key={produit.id} href={`/produit/${produit.id}`}>
                                <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                                    <figure className='bg-gray-100 w-full h-3/5 rounded-xl'>
                                        <img src={produit.photo} alt={produit.titre} />
                                    </figure>
                                    <div className="px-2">
                                        <h2 className="text-md card-title my-2">
                                            {produit.titre.length > 23 ? `${produit.titre.slice(0, 23)}...` : produit.titre}
                                        </h2>
                                        <p className='text-sm text-gray-600 my-2'>{produit.description.length > 66 ? `${produit.description.slice(0, 66)}...` : produit.description}

                                        </p>
                                        <p className={`${produit.promo ? 'line-through text-gray-500' : ''}`}>
                                            {produit.prix} FCFA
                                        </p>
                                        {produit.promo && (
                                            <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                                                - {Math.round(100 - (100 * produit.promo / produit.prix))}%
                                            </p>
                                        )}

                                        {produit.promo && (
                                            <p className="text-red-500">Promo : {produit.promo} FCFA</p>
                                        )}
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            <div className="w-full mb-12 flex flex-col lg:w-12/12">
                <h1 className="text-gray-700 font-bold text-3xl py-4 text-center lg:text-5xl">Découvrez Notre Héritage</h1>
                <p className='text-gray-500 text-center my-2 text-md lg:text-xl lg:w-3/4 mx-auto'>
                    Explorez des produits endogènes authentiques sur notre plateforme dédiée. Plongez dans la richesse culturelle locale et soutenez les artisans et entreprises de votre région. Notre plateforme vous invite à découvrir, apprendre et participer activement à la préservation et à la valorisation de notre patrimoine culturel. Chaque achat et chaque formation contribuent à renforcer le tissu économique et social de votre communauté.
                </p>
            </div>
            <div className="w-full p-4 my-8 flex md:flex-row flex-col items-center gap-3">
                <div className="md:w-1/3 flex justify-center gap-6 p-4">
                    <div className="flex items-center justify-center -mr-3.5">
                        <i className="bi bi-globe text-4xl md:text-5xl text-green-400"></i>
                    </div>
                    <div className="ml-2">
                        <h2 className="font-semibold text-xl md:text-2xl text-gray-500">Authenticité Culturelle</h2>
                        <p className="text-gray-400">Un accès direct à des produits authentiques.</p>
                    </div>
                </div>
                <div className="md:w-1/3 flex justify-center gap-6 p-4">
                    <div className="flex items-center justify-center -mr-3.5">
                        <i className="bi bi-mouse text-4xl md:text-5xl text-green-400"></i>
                    </div>
                    <div className="ml-2">
                        <h2 className="font-semibold text-xl md:text-2xl text-gray-500">Simplicité d'Utilisation</h2>
                        <p className="text-gray-400">Notre plateforme est conçue pour une navigation fluide et intuitive</p>
                    </div>
                </div>
                <div className="md:w-1/3 flex justify-center gap-6 p-4">
                    <div className="flex items-center justify-center -mr-3.5">
                        <i className="bi bi-shield text-4xl md:text-5xl text-green-400"></i>
                    </div>
                    <div className="ml-2">
                        <h2 className="font-semibold text-xl md:text-2xl text-gray-500">Sécurité des Transactions</h2>
                        <p className="text-gray-400">ConsomLocal priorise la sécurité de vos transactions</p>
                    </div>
                </div>
            </div>
            <div className="w-full md:flex md:flex-row flex flex-col items-center justify-center md:gap-8 p-4 my-2">
                <div className="flex items-center justify-center border-red-300 md:w-1/2">
                    <img src={image} alt="image" />
                </div>
                <div className="md:w-1/2 flex flex-col gap-10  pl-8 justify-center">
                    <div className="">
                        <h2 className="font-semibold text-3xl text-gray-600 md:w-2/3 pb-4">Nos Trésors Locaux en Un Clic</h2>
                        <p className="text-gray-400 md:w-3/4">Plongez dans un monde de produits authentiques et locaux. Avec un simple clic, explorez des articles qui célèbrent notre patrimoine culturel tout en bénéficiant d'un paiement rapide et sécurisé.</p>
                    </div>
                    <div className="">
                        <h2 className="font-semibold text-xl text-gray-600"><span className='bg-slate-400 rounded-full  px-3 mr-1'></span>Avant <span className='text-red-500'>Consom<span className='text-green-500'>Local</span ></span></h2>
                        <p className="text-gray-400 text-sm md:w-1/2">Trouver des articles qui reflètent la richesse de notre culture était un défi, souvent limité aux boutiques physiques ou aux recherches en ligne longues et incertaines.</p>
                    </div>
                    <div className="">
                        <h2 className="font-semibold text-xl text-gray-600"><span className='bg-green-500 rounded-full px-3 mr-1'></span>Avec <span className='text-red-500'>Consom<span className='text-green-500'>Local</span ></span></h2>
                        <p className="text-gray-400 text-sm md:w-1/2">Profitez d'une plateforme facile à utiliser pour explorer et acheter des produits authentiques. Notre interface conviviale vous permet de soutenir les artisans locaux tout en bénéficiant d'un paiement rapide et sécurisé.</p>
                    </div>
                </div>
            </div>
            <div className="hero bg-base-200 mt-4 mb-10 lg:h-96">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <img
                        src={photo}
                        className="md:max-w-sm rounded-lg max-h-60 shadow-2xl" />
                    <div className='text-center mt-3 lg:w-4/6'>
                        <h1 className="text-4xl font-bold">Découvrez <span className='text-green-600'>l'Authenticité</span> de l'Afrique.</h1>
                        <p className="py-6 text-gray-500">
                            Plongez dans l'univers fascinant du Burkina Faso avec notre sélection de produits endogènes. Nous vous offrons un accès direct à des créations artisanales uniques, des produits alimentaires savoureux et des objets de décoration authentiques. Chaque achat soutient les artisans locaux et contribue à préserver notre patrimoine culturel.
                        </p>
                        <a href='/apropos' className='mr-1 btn bg-green-500 text-white hover:text-green-400'>Savoir plus</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
