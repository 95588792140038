import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ConfigHost from '../../../ConfigHost';
import { a, useNavigate } from 'react-router-dom';
import '../../../../src/css/ListeProduit.css';

const RecherchePage = () => {
    const location = useLocation();
    const { query } = location.state; // Récupère le mot-clé de recherche
    const [resultats, setResultats] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (query) {
            // Effectuer une requête pour chercher les produits par mot-clé
            axios
                .get(`${ConfigHost.API_URL}/api/produits/?search=${query}`)
                .then((response) => {
                    setResultats(response.data); // Met à jour les résultats de recherche
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Erreur lors de la recherche des produits', error);
                    setIsLoading(false);
                });
        }
    }, [query]);

    if (isLoading) {
        return <p>Chargement des résultats...</p>;
    }

    if (resultats.length === 0) {
        return <p>Aucun produit trouvé pour "{query}".</p>;
    }

    return (
        <div className="container mx-auto mt-32">
            <p className='text-xl mb-7'>
                <a href="/" className='mr-1 hover:text-green-400'>
                    Accueil
                </a>
                / <span className='text-gray-500'>Recherche de produits</span></p>
            <h2 className="text-2xl font-bold">Résultats pour "{query}"</h2>

            <div className="w-full rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 mt-1 p-8">
                {resultats.map((produit) => (
                    <a key={produit.id} href={`/produit/${produit.id}`}>
                        <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                            <figure className='bg-gray-100 w-full h-2/5 rounded-xl'>
                                <img src={produit.photo} alt={produit.titre} />
                            </figure>
                            <div className="px-2">
                                <h2 className="text-md card-title my-2">
                                    {produit.titre.length > 23 ? `${produit.titre.slice(0, 23)}...` : produit.titre}
                                </h2>
                                <p className='text-sm text-gray-600 my-2'>{produit.description.length > 66 ? `${produit.description.slice(0, 66)}...` : produit.description}

                                </p>
                                <p className={`${produit.promo ? 'line-through text-gray-500' : ''}`}>
                                    {produit.prix} FCFA
                                </p>
                                {produit.promo && (
                                    <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                                        - {Math.round(100 - (100 * produit.promo / produit.prix))}%
                                    </p>
                                )}

                                {produit.promo && (
                                    <p className="text-red-500">Promo : {produit.promo} FCFA</p>
                                )}
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default RecherchePage;
